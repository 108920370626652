//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import EditPageButton from '~/components/edit-page-button'
import CollectionModal from '~/components/collection-modal'
import MultipanelModal from '~/components/multipanel-modal'
import CollectionView from '~/components/collection-view'
import Dropdown from '~/components/dropdown'
import RestModel from '~/components/rest-model'
import RestImg from '~/components/rest-img'
import RichTextarea from '~/components/rich-textarea'
import InputFocus from '~/components/input-focus'
import TagInput from '~/components/tag-input'
import RestModelCreateView from '~/components/rest-model-create-view'
import AdminLangSwitcher from '~/components/admin-lang-switcher'
import AdminPanelEditForm from '~/components/admin-panel-edit-form'
import AdminPanelEditList from '~/components/admin-panel-edit-list'
import AdminMediaSelector from '~/components/admin-media-selector'
import AdminPanelJsonEditList from '~/components/admin-panel-edit-json-list'
import AdminPanelFooterEditList from '~/components/admin-panel-edit-footer'
import LangSwitcher from '~/components/admin-ui-lang-switcher'
import RestString from '~/components/rest-string'
import { error } from 'util';
import svgSymbol from '~/components/svg-symbol'
import { saveAs } from 'file-saver';
import vClickOutside from 'v-click-outside'
// WARNING: do *NOT* import it like this, because it introduces rollup stuff that break SSR
// import LiquorTree from 'liquor-tree'
import LiquorTree from 'liquor-tree/src/components/TreeRoot.vue'
import '@mdi/font/css/materialdesignicons.css'

import {
	VApp,
	VIcon,
	VImg,
	VToolbar,
	VBtnToggle,
	VBtn,
	VTooltip,
	VSpacer,
	VCard,
	VCardActions,
	VCardTitle,
	VCardText,
	VExpansionPanels,
	VExpansionPanel,
	VExpansionPanelHeader,
	VExpansionPanelContent,
	VTextField,
	VTextarea,
	VCol,
	VRow,
	VDialog,
	VContainer,
	VList,
	VListItem,
	VListItemTitle,
	VDataTable,
	VMenu,
	VChip,
	VFadeTransition,
	VDatePicker,
	VSwitch,
	VSelect,
	VProgressLinear,
	VProgressCircular,
	VAlert,
	VBottomSheet,
	VSheet,
	VCombobox,
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

// import JsonEditor from 'vue-json-edit'
import JsonEditor from '~/lib/vue-json-edit/src/JsonEditor.vue'
// import JsonView from 'vue-json-edit/src/JsonView.vue'
// import ArrayView from 'vue-json-edit/src/ArrayView.vue'
// import vuedraggable from 'vuedraggable'

import { isChrome, isChromeIOS, isTrident, isEdge, isSafari, isFirefox, isYandex } from '~/plugins/browser-detect.js'

const cloneDeep = require('lodash.clonedeep');
const slugify = require('slugify');

import '~/assets/styles/admin-panel.sass'
import '~/assets/styles/admin-editor.sass'
// import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import { mdiCheckCircle, mdiCheckboxBlankCircleOutline, mdiImageOffOutline } from '@mdi/js';
import { read } from 'fs'

const openInWindowSVG = "M20 3H4C2.897 3 2 3.897 2 5v14c0 1.103 0.897 2 2 2h5v-2H4V7h16v12h-5v2h5c1.103 0 2-0.897 2-2V5C22 3.897 21.103 3 20 3M13 21L13 16 16 16 12 11 8 16 11 16 11 21Z"

// consola.debug('mdiCheckCircle: ', mdiCheckCircle)

const isDev = process.env.NODE_ENV !== 'production';
const MEDIA_HOST = process.env.MEDIA_HOST || (typeof window!=='undefined' ? window.location.host : '');
consola.debug('process.env.MEDIA_HOST: ', process.env.MEDIA_HOST)

function toDateOnly(obj)
{
	if (!obj)	return null;
	if (obj.date)	obj = obj.date;
	var d = new Date(obj);
	return `${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}`
}

const subpaths = ['block_list', 'extra_data', 'instructor_list'];
function trekBlockTree(obj, matchKey, matchVal)
{
	if (obj[matchKey] == matchVal)	return obj;
	for (var i = 0; i < subpaths.length; i++)
	{
		if ( Array.isArray(obj[ subpaths[i] ]) )
		{
			let list = obj[ subpaths[i] ];
			for (var j = 0; j < list.length; j++)
			{
				var ret = trekBlockTree(list[j], matchKey, matchVal)
				if (ret)	return ret;
			}
		}
	}

	return null;
}

const blockCopyableFields = ['owner_table', 'block_type', 'img', 'mobile_img', 'extra_data_table', 'has_taxonomy', 'title', 'short_title', 'subtext', 'content', 'url', 'cta_label', 'url_in_new_tab', 'url_in_iframe'];
const courseCopyableFields = ['img', 'thumbnail', 'seats_remaining', 'identifier', 'instructor_id', 'instructor_list', 'is_published', 'title', 'subtitle', 'duration', 'duration_info', 'tuition', 'discount', 'discount_info', 'tuition_info', 'name', 'registration_label', 'registration_label2',];

const menuActions = Object.freeze([
	{ label: '+ Logo', insertObject: { type: 'logo', label: '', url: ''}	},
	{ label: '+ Spacer', insertObject: { type: 'spacer' }	},
	{ label: '+ Language List', insertObject: { type: 'lang-list', }	},
	{ label: '+ Label', insertObject: { type: 'label', label: '', mobileLabel: ''}	},
	{ label: '+ Link', insertObject: { type: 'link', label: '', mobileLabel: '', url: '', is_external: false}	},
	{ label: '+ Form', insertObject: { type: 'form-button', label: '', mobileLabel: '', url: ''}	},
]);


export default {
	name: 'admin-panel',

	directives: {
		Ripple,
		clickOutside: vClickOutside.directive
	},

	components:
	{
		VApp,
		VIcon,
		VImg,
		VToolbar,
		VBtnToggle,
		VBtn,
		VTooltip,
		VSpacer,
		VCard,
		VCardActions,
		VCardTitle,
		VCardText,
		VExpansionPanels,
		VExpansionPanel,
		VExpansionPanelHeader,
		VExpansionPanelContent,
		VTextField,
		VTextarea,
		VCol,
		VRow,
		VDialog,
		VContainer,
		VList,
		VListItem,
		VListItemTitle,
		VDataTable,
		VMenu,
		VChip,
		VFadeTransition,
		VDatePicker,
		VSwitch,
		VSelect,
		VProgressLinear,
		VProgressCircular,
		VAlert,
		VBottomSheet,
		VSheet,
		VCombobox,

		EditPageButton,
		CollectionModal,
		MultipanelModal,
		CollectionView,
		'v-dropdown': Dropdown,
		'rest-model': RestModel,
		TagInput,
		RestModel,
		RestImg,
		'rich-textarea': RichTextarea,
		InputFocus,
		RestModelCreateView,
		AdminLangSwitcher,
		AdminPanelEditForm,
		AdminPanelEditList,
		AdminPanelJsonEditList,
		AdminPanelFooterEditList,
		AdminMediaSelector,
		'tree': LiquorTree,
		'svg-symbol': svgSymbol,
		// 'json-editor': JsonEditor,
		LangSwitcher,
		JsonEditor,
		// JsonView,
		// ArrayView,
		// 'draggable': vuedraggable,
		RestString
	},

	data()
	{
		return {
			alert:
			{
				msg: '',
				type: '',
			},

			disableEditing: false,

			showNewPageDialog: false,
			showModelSelector: false,
			modelSelectorUrl: '',
			modelSelectorLabel: '',
			modelSelectorBlockType: '',
			modelSelectorGetParams: null,

			rules:
			{
				required: x => !!x || 'Required',
			},

			newPageTypes:
			[
				{ label: 'Course', value: 'course' },
				{ label: 'Learning school', value: 'training' },
				{ label: 'Service child', value: 'service-child' },
				{ label: 'Expertise child', value: 'expertise-child' },
				{ label: 'Generic', value: 'generic' },
				// { label: 'Post', value: 'post' },
				{ label: 'Corporate', value: 'corporate' },
			],
			newPageLoading: false,
			newPageTitle: '',
			newPageSlug: '',
			newPageSlugError: null,
			newPageType: '',
			newPageDescription: '',
			newPageCourseLocales: null,

			menuActions: menuActions,

			panelButton: null,
			pagesPanel:
			{
				selectable: false,
				selected: [],
				selectedLocale: 1, // default to Greek
				selectedPageLocales: [],
				// loading: true,
				search: '',
				headers: [
					{
						text: 'ID',
						value: 'page_id',
						align: 'center',
						// sortable: false
					},
					{
						text: 'Domain',
						align: 'center',
						value: 'site_id',
						// filter: (value, search, item) =>
						// 	item.locale_id == this.allLanguages[this.pagesPanel.selectedLocale].id,
					},
					{
						text: 'Title',
						align: 'left',
						value: 'title',
						filter: (value, search, item) =>
							item.locale_id == this.allLanguages[this.pagesPanel.selectedLocale].id,

					},
					{
						text: 'Link',
						value: 'link',
						align: 'center',
						sortable: false
					},
					// {
					// 	text: 'Slug',
					// 	align: 'left',
					// 	value: 'slug',
					// },
					{
						text: 'Page Type',
						value: 'page_type',
						filter: this.filterPages,
						sortable: false
					},
					{
						text: 'Thumbnail',
						value: 'thumbnail',
						align: 'center',
						sortable: false
					},
					// {
					// 	text: 'Locale',
					// 	value: 'locale_id',
					// 	align: 'center',
					// 	sortable: false
					// },
					{
						text: 'Published',
						value: 'is_published',
						align: 'center',
					},
					{
						text: 'Last Updated',
						value: 'updated_at',
						align: 'center',
					},
					// {
					// 	text: 'Action',
					// 	value: 'action',
					// 	align: 'center',
					// 	sortable: false
					// },
				],
				filters: []
			},

			icon:
			{
				circleCheck: mdiCheckCircle,
				circleEmpty: mdiCheckboxBlankCircleOutline,
				imgEmpty: mdiImageOffOutline,
				openInWindow: openInWindowSVG,
			},

			// stores "rest-model" components that have changed
			modelStore: {},
			// stores "rest-string" components that have changed
			stringStore: {},

			modelDict:
			{
				// each of the following stores key-value references to objects from the "pageLocaleData" tree
				// not: the object "id" is used as key, not the parent id
				block: {},
				course: {},
				date: {},
				instructor: {},
				page: {},
				string: {},
				taxonomy: {},
				quote: {},
				announcement: {},
				site: {},
				json: {},

			},

			modifiedModelLists:
			{
				block: {},
				course: {},
				date: {},
				instructor: {},
				page: {},
				string: {},
				taxonomy: {},
				quote: {},
				announcement: {},
				site: {},
				json: {},
			},

			isSwitchingLocales: false,

			// NOTE: if you put 'pageLocaleData' in the store,
			//			it will not be allowed to alter its contents directly
			pageLocaleData: [],
			stringLocaleData: [],
			pageLocaleDataReady: false,

			// selectedPageLang: 0,
			// selectedPage: null,
			selectedPagePanels: [0, 1, 2, 3, 4],
			selectedPageLang: 1,
			selectedPageJSONLD: [],
			selectedJSONLDLang: 1,

			selectedCollection: null,
			// selectedCollectionItem: null, // this is now a computed property
			// this is a list of all the items with their locale variants in a flat array
			selectedCollectionLocaleItems: [],
			// savingCollection: false,
			collectionAccordions: [],

			course_date_menu: false,

			site_seo:
			{
				fb_pixel: '',
				ga: '',
				gtag: '',
			},

			siteJSONLD: [],
			siteLang: 1,

			gotoTrainingCredentials: {
				json: {
					consumerKey: '',
					consumerSecret: '',
					responseKey: '',
				},
				type:'credentials',
				owner_url: `@site`,
				name: 'gototraining' ,
				locale_id: 1,
			},
			credentialsLoading: false,

			courseJSONLD: null,

			// site_struct_data:
			// {
			// 	name: 'mike',
			// 	age: 23,
			// 	phone: '18552129932',
			// 	address: ['AAA C1', 'BBB C2']
			// },
			// [
			// 	{ text: "Node 1", state: {}, data: { key: 'key1', value: 'val' } },
			// 	{ text: "Node 2", state: {}, data: { key: 'key2', value: 'val' } },
			// 	{ text: "Node 3", state: {}, data: { key: 'key3', value: 'val' } },
			// 	{ text: "Node 4", state: {}, data: { key: 'key4', value: 'val' } },
			// 	{ text: "Node 5", state: {}, data: { key: 'key5', value: 'val' } },
			// ],

			cmsListEdit:
			{
				label: "",
				type: null,
				block_type: null,
				// parentBlock: null,
				grid: '',
				gridCell: '',
				localeList: [],
				extra_data_table: null,
				fields: [],
				data: null,
			},

			collections: [
				// {
				// 	label: 'Users',
				// 	url: 'auth/user', // ?
				// 	data: [],
				// 	sortby: 'email',
				// 	headers: [
				// 		{
				// 			text: 'username',
				// 			value: 'username',
				// 			align: 'left',
				// 			type: 'string',
				// 		},
				// 		{
				// 			text: 'email',
				// 			value: 'email',
				// 			type: 'string',
				// 			// align: 'center',
				// 			// sortable: false
				// 		},
				// 		{
				// 			text: 'Active',
				// 			value: 'is_active',
				// 			type: 'boolean',
				// 			align: 'center',
				// 			// sortable: false
				// 		},
				// 		{
				// 			text: 'Admin',
				// 			value: 'is_admin',
				// 			type: 'boolean',
				// 			align: 'center',
				// 			// sortable: false
				// 		},
				// 		{
				// 			text: 'Last Updated',
				// 			value: 'updated_at',
				// 			type: 'date',
				// 			align: 'center',
				// 		},
				// 	]
				// },
				{
					label: 'Media',
					url: 'media',
					isButton: true,
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'name',
				},
				{
					label: 'Strings',
					label_singular: 'String',
					url: 'string',
					isButton: true,
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'name',
					itemIdProp: 'string_id',
					headers: [
						{
							width: '20vw',
							text:'key',
							value:'key',
							alight: 'end',
							slot: 'item.key',
							// align: 'center',
							type: 'string',
							// filter: this.filterAnnouncements,
							// filter: (valuem, search, item) =>
							// item.key.indexOf(search) > -1 || item.value.indexOf(search) > -1,
						},
						{
							text:'value',
							value:'value'
						}
					]
				},
				{
					label: 'Locales',
					label_singular: 'Locale',
					url: 'locale',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'name',
					itemIdProp: 'id',
					itemParentIdProp: 'id', // <- this is a hack kindof
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'id',
							slot: 'item.id',
							align: 'center',
							type: 'number',
						},
						{
							text: 'Name',
							value: 'name',
							slot: 'item.name',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Code',
							value: 'lang_code',
							slot: 'item.lang_code',
							type: 'string',
							align: 'center',
							// sortable: false
						},
						{
							text: 'Country Code',
							value: 'country_code',
							slot: 'item.country_code',
							type: 'string',
							align: 'center',
							// sortable: false
						},
						{
							text: 'Image',
							value: 'img_path',
							slot: 'item.img_path',
							type: 'image',
							align: 'center',
							// sortable: false
						},
						{
							text: 'Active',
							value: 'is_active',
							slot: 'item.is_active',
							type: 'boolean',
							align: 'center',
							// sortable: false
						},
						{
							text: 'Is Default',
							value: 'is_default',
							slot: 'item.is_default',
							type: 'boolean',
							align: 'center',
							// sortable: false
						},
						{
							text: 'Last Updated',
							value: 'updated_at',
							slot: 'item.updated_at',
							type: 'date',
							align: 'center',
						},
					]
				},
				{
					label: 'Menus',
					label_singular: 'Menu',
					url: 'json',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'name',
					itemIdProp: 'id',
					itemParentIdProp: 'owner_id',
					apiGetParams: { type: 'menu' },
					apiCreateParams: { type: 'menu', json: [{ type: 'spacer' }] },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						// {
						// 	text: 'id',
						// 	value: 'id',
						// 	slot: 'item.id',
						// 	align: 'center',
						// 	type: 'number',
						// },
						{
							text: 'Name',
							value: 'name',
							slot: 'item.name',
							align: 'left',
							type: 'string',
							clone_to_all: true,
							filter: (value, search, item) =>
							item.locale_id == this.allLanguages[this.collections[3].selectedLocale].id,

						},
						{
							text: 'Default',
							value: 'is_default',
							slot: 'item.is_default',
							type: 'boolean',
							align: 'center',
							clone_to_all: true,
							// sortable: false
						},
						{
							text: 'Last Updated',
							value: 'updated_at',
							slot: 'item.updated_at',
							type: 'date',
							align: 'center',
						},
					]
				},
				{
					label: 'Footers',
					label_singular: 'Footer',
					url: 'json',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'name',
					itemIdProp: 'id',
					itemParentIdProp: 'owner_id',
					apiGetParams: { type: 'footer' },
					// apiCreateParams: { type: 'footer', json: [{ type: 'spacer' }] },
					apiCreateParams: { type: 'footer', json: [{ name: 'upper', list:[{ type: 'logo', label: '', url: '' }] }, { name: 'lower', list:[{ type: 'spacer'}] }] },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						// {
						// 	text: 'id',
						// 	value: 'id',
						// 	slot: 'item.id',
						// 	align: 'center',
						// 	type: 'number',
						// },
						{
							text: 'Name',
							value: 'name',
							slot: 'item.name',
							align: 'left',
							type: 'string',
							clone_to_all: true,
							filter: (value, search, item) =>
							item.locale_id == this.allLanguages[this.collections[4].selectedLocale].id,

						},
						{
							text: 'Default',
							value: 'is_default',
							slot: 'item.is_default',
							type: 'boolean',
							align: 'center',
							clone_to_all: true,
							// sortable: false
						},
						{
							text: 'Last Updated',
							value: 'updated_at',
							slot: 'item.updated_at',
							type: 'date',
							align: 'center',
						},
					]
				},
				{
					label: 'Announcements',
					label_singular: 'Announcement',
					url: 'announcement',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'title',
					itemIdProp: 'id',
					itemParentIdProp: 'block_id',
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'block_id',
							slot: 'item.block_id',
							align: 'center',
							type: 'number',
							// filter: this.filterAnnouncements,
							filter: (value, search, item) =>
							item.locale_id == this.allLanguages[this.collections[5].selectedLocale].id,
						},
						{
							text: 'From',
							value: 'from',
							slot: 'item.from',
							align: 'center',
							type: 'date',
							clone_to_all: true,
						},
						{
							text: 'To',
							value: 'to',
							slot: 'item.to',
							align: 'center',
							type: 'date',
							clone_to_all: true,
						},
						{
							text: 'Match',
							value: 'match_list',
							slot: 'item.match_list',
							align: 'left',
							type: 'string',
							clone_to_all: true,
						},

						// {
						// 	text: 'Title',
						// 	value: 'title',
						// 	slot: 'item.title',
						// 	align: 'left',
						// 	type: 'string',
						// },
						// {
						// 	text: 'Short Title',
						// 	value: 'short_title',
						// 	slot: 'item.short_title',
						// 	align: 'left',
						// 	type: 'string',
						// },
						// {
						// 	text: 'Subtitle',
						// 	value: 'subtext',
						// 	slot: 'item.subtext',
						// 	align: 'left',
						// 	type: 'string',
						// },
						{
							text: 'Content',
							value: 'content',
							slot: 'item.content',
							align: 'left',
							type: 'html',
						},
						// {
						// 	text: 'Call to Action',
						// 	value: 'cta_label',
						// 	slot: 'item.cta_label',
						// 	align: 'center',
						// 	type: 'string',
						// },
						// {
						// 	text: 'Link',
						// 	value: 'url',
						// 	slot: 'item.url',
						// 	align: 'center',
						// 	type: 'string',
						// },

						// {
						// 	text: 'Active',
						// 	value: 'is_active',
						// 	slot: 'item.is_active',
						// 	type: 'boolean',
						// 	align: 'center',
						// 	// sortable: false
						// },
						{
							text: 'Last Updated',
							value: 'updated_at',
							slot: 'item.updated_at',
							type: 'date',
							align: 'center',
						},
					]
				},
				{
					label: 'Instructors',
					label_singular: 'Instructor',
					url: 'instructor',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'name',
					itemIdProp: 'instructor_id',
					itemParentIdProp: 'instructor_id',
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'instructor_id',
							slot: 'item.instructor_id',
							align: 'center',
							type: 'number',
							// filter: this.filterInstructors,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[6].selectedLocale].id,
						},
						{
							text: 'Name',
							value: 'name',
							slot: 'item.name',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Job',
							value: 'job_title',
							slot: 'item.job_title',
							type: 'string',
							// align: 'center',
							// sortable: false
						},
						{
							text: 'Email',
							value: 'email',
							slot: 'item.email',
							type: 'string',
							align: 'center',
							// sortable: false
						},
						{
							text: 'Phone',
							value: 'phone',
							slot: 'item.phone',
							type: 'string',
							align: 'center',
							// sortable: false
						},
						{
							text: 'Image',
							value: 'img_path',
							slot: 'item.img_path',
							type: 'image',
							align: 'center',
							clone_to_all: true,
							// sortable: false
						},
						{
							text: 'Active',
							value: 'is_published',
							slot: 'item.is_published',
							type: 'boolean',
							align: 'center',
							clone_to_all: true,
							// sortable: false
						},
						{
							text: 'Last Updated',
							value: 'updated_at',
							slot: 'item.updated_at',
							type: 'date',
							align: 'center',
						},
					]
				},
				{
					label: 'Quotes',
					label_singular: 'Quote',
					url: 'quote',
					block_type: 'quote',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'name',
					itemIdProp: 'quote_id',
					itemParentIdProp: 'quote_id',
					apiCreateParams: { block_type: 'quote' },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'quote_id',
							slot: 'item.quote_id',
							align: 'center',
							type: 'number',
							// filter: this.filterQuotes,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[7].selectedLocale].id,
						},
						{
							text: 'Name',
							value: 'name',
							slot: 'item.name',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Job',
							value: 'job_title',
							slot: 'item.job_title',
							type: 'string',
							// align: 'center',
							// sortable: false
						},
						{
							text: 'Text',
							value: 'text',
							slot: 'item.text',
							type: 'string',
							align: 'left',
							// sortable: false
						},
						// {
						// 	text: 'Rating',
						// 	value: 'rating',
						// 	slot: 'item.rating',
						// 	type: 'string',
						// 	align: 'center',
						// 	// sortable: false
						// },
						{
							text: 'Image',
							value: 'img_path',
							slot: 'item.img_path',
							type: 'image',
							align: 'center',
							clone_to_all: true,
							// sortable: false
						},
						// {
						// 	text: 'Active',
						// 	value: 'is_published',
						// 	slot: 'item.is_published',
						// 	type: 'boolean',
						// 	align: 'center',
						// 	// sortable: false
						// },
						{
							text: 'Last Updated',
							value: 'updated_at',
							slot: 'item.updated_at',
							type: 'date',
							align: 'center',
						},
					]
				},
				{
					label: 'Courses',
					label_singular: 'Course',
					url: 'course',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'updated_at',
					itemIdProp: 'course_id',
					itemParentIdProp: 'course_id',
					registration_dates: [],
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'warnCourseDelete',
					acceptDeleteAction: 'deleteCollectionItems',
					onSave: 'validateCourse',
					props: [
						{
							Label: 'Name',
							value: 'name',
							type: 'string',
							// clone_to_all: true,
						},
						{
							Label: 'Title',
							value: 'title',
							type: 'string',
							// clone_to_all: true,
						},
						{
							Label: 'Description',
							value: 'subtitle',
							type: 'string',
							// clone_to_all: true,
						},
						{
							Label: 'identifier',
							value: 'identifier',
							type: 'string',
							clone_to_all: true,
						},
						{
							Label: 'Duration',
							value: 'duration',
							type: 'string',
							// clone_to_all: true,
						},
						{
							Label: 'Duration info',
							value: 'duration_info',
							type: 'string',
							// clone_to_all: true,
						},
						{
							Label: 'Tuition',
							value: 'tuition',
							type: 'string',
							// clone_to_all: true,
						},
						{
							Label: 'Tuition info',
							value: 'tuition_info',
							type: 'string',
							// clone_to_all: true,
						},
						{
							Label: 'Discount',
							value: 'discount',
							type: 'string',
							// clone_to_all: true,
						},
						{
							Label: 'Discount info',
							value: 'discount_info',
							type: 'string',
							// clone_to_all: true,
						},
						{
							Label: 'Instructors',
							value: 'instructor_list',
							type: 'array',
							clone_to_all: true,
						},
						{
							Label: 'Category List',
							value: 'category_list',
							type: 'array',
							clone_to_all: true,
						},
						{
							Label: 'Language List',
							value: 'language_list',
							type: 'array',
							clone_to_all: true,
						},
						{
							Label: 'Class Format',
							value: 'format_list',
							type: 'array',
							clone_to_all: true,
						},
						{
							Label: 'Image',
							value: 'img',
							type: 'image',
							clone_to_all: true,
						},
						{
							Label: 'Thumbnail',
							value: 'thumbnail',
							type: 'image',
							clone_to_all: true,
						},
						{
							Label: 'Remaining Seats',
							value: 'seats_remaining',
							type: 'number',
							clone_to_all: true,
						},
						{
							Label: 'Active',
							value: 'is_published',
							type: 'boolean',
							clone_to_all: true,
						},
					],
					headers: [
						{
							text: 'id',
							value: 'course_id',
							slot: 'item.course_id',
							align: 'center',
							type: 'number',
							// filter: this.filterCourses,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[8].selectedLocale].id,
						},
						// {
						// 	text: 'Name',
						// 	value: 'name',
						// 	slot: 'item.name',
						// 	align: 'left',
						// 	type: 'string',
						// },
						{
							text: 'Title',
							value: 'title',
							slot: 'item.title',
							align: 'left',
							type: 'string',
						},
						// {
						// 	text: 'Subtitle',
						// 	value: 'subtitle',
						// 	slot: 'item.subtitle',
						// 	align: 'left',
						// 	type: 'string',
						// },
						{
							text: 'Identifier',
							value: 'identifier',
							slot: 'item.identifier',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Slug',
							value: 'slug',
							slot: 'item.slug',
							type: 'string',
							align: 'left',
							clone_to_all: true,
							// sortable: false
						},
						{
							text: 'Link',
							type: 'url',
							value: 'slug',
							slot: 'item.slug',
							align: 'center',
							sortable: false
						},
						{
							text: 'Seats Remaining',
							value: 'seats_remaining',
							slot: 'item.seats_remaining',
							type: 'number',
							align: 'center',
							clone_to_all: true,
							// sortable: false
						},
						{
							text: 'Thumbnail',
							value: 'thumbnail',
							slot: 'item.thumbnail',
							type: 'image',
							align: 'center',
							clone_to_all: true,
							// sortable: false
						},
						// {
						// 	text: 'Image',
						// 	value: 'img',
						// 	slot: 'item.img',
						// 	type: 'image',
						// 	align: 'center',
						// 	clone_to_all: true,
						// 	// sortable: false
						// },
						{
							text: 'Active',
							value: 'is_published',
							slot: 'item.is_published',
							type: 'boolean',
							align: 'center',
							clone_to_all: true,

							// sortable: false
						},
						{
							text: 'Last Updated',
							value: 'updated_at',
							slot: 'item.updated_at',
							type: 'date',
							align: 'center',
						},
					]
				},
				// {
				// 	label: 'Redirects',
				// 	label_singular: 'Redirect',
				// 	url: 'redirect',
				// 	data: [],
				// 	search: '',
				// 	selected: [],
				// 	selectedLocale: 1, // default to Greek
				// 	sortby: 'id',
				// 	itemIdProp: 'id',
				// 	itemParentIdProp: 'id', // <- this is a hack kindof
				// 	onClick: 'editCollectionItem',
				// 	onNew: 'createCollectionItem',
				// 	onDelete: 'deleteCollectionItems',
				// 	siteSelectItems: [
				// 		{ text: 'corporate', value: 1 },
				// 		{ text: 'academy', value: 2 },
				// 	],
				// 	headers: [
				// 		{
				// 			text: 'id',
				// 			value: 'id',
				// 			slot: 'item.id',
				// 			align: 'center',
				// 			type: 'number',
				// 		},
				// 		{
				// 			text: 'Active',
				// 			value: 'is_active',
				// 			slot: 'item.is_active',
				// 			type: 'boolean',
				// 			align: 'center',
				// 			// sortable: false
				// 		},
				// 		{
				// 			text: 'from',
				// 			value: 'from',
				// 			slot: 'item.from',
				// 			align: 'left',
				// 			type: 'string',
				// 		},
				// 		{
				// 			text: 'to',
				// 			value: 'to',
				// 			slot: 'item.to',
				// 			align: 'left',
				// 			type: 'string',
				// 		},
				// 		{
				// 			text: 'site',
				// 			value: 'site_id',
				// 			slot: 'item.site_id',
				// 			type: 'map',
				// 			map: {
				// 				1: 'corporate',
				// 				2: 'academy',
				// 			},
				// 			align: 'center',
				// 			// sortable: false
				// 		},
				// 		{
				// 			text: 'Last Updated',
				// 			value: 'updated_at',
				// 			slot: 'item.updated_at',
				// 			type: 'date',
				// 			align: 'center',
				// 		},
				// 	]
				// },
				{
					label: 'FAQs',
					label_singular: 'Faq',
					hidden: true,
					url: 'block',
					block_type: 'faq',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'name',
					itemIdProp: 'block_id',
					itemParentIdProp: 'block_id',
					apiGetParams: { block_type: 'faq' },
					apiCreateParams: { block_type: 'faq' },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'block_id',
							slot: 'item.block_id',
							align: 'center',
							type: 'number',
							// filter: this.filterQuotes,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[9].selectedLocale].id,
						},
						{
							text: 'Title',
							value: 'title',
							slot: 'item.title',
							align: 'left',
							type: 'string',
						},
					]
				},
				{
					label: 'Sidebars',
					label_singular: 'Sidebar',
					hidden: true,
					url: 'block',
					block_type: 'hero-sidebar',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'short_title',
					itemIdProp: 'block_id',
					itemParentIdProp: 'block_id',
					apiGetParams: { block_type: 'hero-sidebar' },
					apiCreateParams: { block_type: 'hero-sidebar' },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'block_id',
							slot: 'item.block_id',
							align: 'center',
							type: 'number',
							// filter: this.filterQuotes,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[9].selectedLocale].id && this.removeEmptyFilter(valuem, search, item),
						},
						{
							text: 'Header',
							value: 'short_title',
							slot: 'item.short_title',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Title',
							value: 'title',
							slot: 'item.title',
							align: 'left',
							type: 'string',
						},
					]
				},
				{
					label: 'Services',
					label_singular: 'Service',
					hidden: true,
					url: 'block',
					block_type: 'service',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'short_title',
					itemIdProp: 'block_id',
					itemParentIdProp: 'block_id',
					apiGetParams: { block_type: 'service' },
					apiCreateParams: { block_type: 'service' },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'block_id',
							slot: 'item.block_id',
							align: 'center',
							type: 'number',
							// filter: this.filterQuotes,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[9].selectedLocale].id && this.removeEmptyFilter(valuem, search, item),
						},
						{
							text: 'Title',
							value: 'short_title',
							slot: 'item.short_title',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Url',
							value: 'url',
							slot: 'item.url',
							align: 'left',
							type: 'string',
						},
					]
				},
				{
					label: 'Updates',
					label_singular: 'Update',
					hidden: true,
					url: 'block',
					block_type: 'update',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'title',
					itemIdProp: 'block_id',
					itemParentIdProp: 'block_id',
					apiGetParams: { block_type: 'update' },
					apiCreateParams: { block_type: 'update' },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'block_id',
							slot: 'item.block_id',
							align: 'center',
							type: 'number',
							// filter: this.filterQuotes,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[9].selectedLocale].id && this.removeEmptyFilter(valuem, search, item),
						},
						{
							text: 'Img',
							value: 'img',
							slot: 'item.img',
							align: 'left',
							type: 'image',
							clone_to_all: true,
						},
						{
							text: 'Title',
							value: 'title',
							slot: 'item.title',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Url',
							value: 'url',
							slot: 'item.url',
							align: 'left',
							type: 'string',
						},
					]
				},
				{
					label: 'Partners',
					label_singular: 'Partner',
					hidden: true,
					url: 'block',
					block_type: 'partner',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'title',
					itemIdProp: 'block_id',
					itemParentIdProp: 'block_id',
					apiGetParams: { block_type: 'partner' },
					apiCreateParams: { block_type: 'partner' },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'block_id',
							slot: 'item.block_id',
							align: 'center',
							type: 'number',
							// filter: this.filterQuotes,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[9].selectedLocale].id && this.removeEmptyFilter(valuem, search, item),
						},
						{
							text: 'Img',
							value: 'img',
							slot: 'item.img',
							align: 'left',
							type: 'image',
							clone_to_all: true,
						},
						{
							text: 'Title',
							value: 'title',
							slot: 'item.title',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Url',
							value: 'url',
							slot: 'item.url',
							align: 'left',
							type: 'string',
						},
					]
				},
				{
					label: 'Trustmarks',
					label_singular: 'Trustmark',
					hidden: true,
					url: 'block',
					block_type: 'trustmark',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'url',
					itemIdProp: 'block_id',
					itemParentIdProp: 'block_id',
					apiGetParams: { block_type: 'trustmark' },
					apiCreateParams: { block_type: 'trustmark' },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'block_id',
							slot: 'item.block_id',
							align: 'center',
							type: 'number',
							// filter: this.filterQuotes,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[9].selectedLocale].id && this.removeEmptyFilter(valuem, search, item),
						},
						{
							text: 'Img',
							value: 'img',
							slot: 'item.img',
							align: 'left',
							type: 'image',
							clone_to_all: true,
						},
						{
							text: 'Url',
							value: 'url',
							slot: 'item.url',
							align: 'left',
							type: 'string',
						},
					]
				},
				{
					label: 'Expertise',
					label_singular: 'Expertise',
					hidden: true,
					url: 'block',
					block_type: 'expertise',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'short_title',
					itemIdProp: 'block_id',
					itemParentIdProp: 'block_id',
					apiGetParams: { block_type: 'expertise' },
					apiCreateParams: { block_type: 'expertise' },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'block_id',
							slot: 'item.block_id',
							align: 'center',
							type: 'number',
							// filter: this.filterQuotes,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[9].selectedLocale].id && this.removeEmptyFilter(valuem, search, item),
						},
						{
							text: 'Title',
							value: 'short_title',
							slot: 'item.short_title',
							align: 'left',
							type: 'string',
						},
					]
				},
				{
					label: 'Sticky',
					label_singular: 'Sticky',
					hidden: true,
					url: 'block',
					block_type: 'sticky-info',
					data: [],
					search: '',
					selected: [],
					selectedLocale: 1, // default to Greek
					sortby: 'subtext',
					itemIdProp: 'block_id',
					itemParentIdProp: 'block_id',
					apiGetParams: { block_type: 'sticky-info' },
					apiCreateParams: { block_type: 'sticky-info' },
					onClick: 'editCollectionItem',
					onNew: 'createCollectionItem',
					onDelete: 'deleteCollectionItems',
					headers: [
						{
							text: 'id',
							value: 'block_id',
							slot: 'item.block_id',
							align: 'center',
							type: 'number',
							// filter: this.filterQuotes,
							filter: (valuem, search, item) =>
							item.locale_id == this.allLanguages[this.collections[9].selectedLocale].id && this.removeEmptyFilter(valuem, search, item),
						},
						{
							text: 'Tile',
							value: 'subtext',
							slot: 'item.sticky-info',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Subtitle',
							value: 'short_title',
							slot: 'item.short_title',
							align: 'left',
							type: 'string',
						},
						{
							text: 'Title',
							value: 'title',
							slot: 'item.title',
							align: 'left',
							type: 'string',
						},
					]
				},
			],


			courseDeletionWarning:
			{
				show: false,
				acceptCallback: null,
				cancelCallback: null
			},

			showAdminPanel: null,
			showAdminSubPanel: null,
			showCollectionModal: false,
			showModal: false,
			// showCourses: false,
			// showInstructors: false,
			// showQuotes: false,
			// showBlocks: false,
			// showSettings: false,
			collectionModalParams:
			{
				url: null,
				itemStyle: {},
				itemClass: {},
			},
			extraParams: {},
			createCmds: [
				{ label: 'Generic Page', cmd: 'createGenericPage' },
				{ label: 'Course', cmd: 'createCourse' },
				{ label: 'Instructor', cmd: 'createInstructor' },
				{ label: 'Quote', cmd: 'createQuote' },
				{ label: 'Locale', cmd: 'createLocale' },
				{ label: 'Key Benefit', cmd: 'createKeyBenefit' },
				{ label: 'Course outline module', cmd: 'createModule' },
			],
			panels: [],
			pageModel:
			{
				id: null,
				page_id: null,
				page_type: null,
				slug: null,
				title: null,
				description: null,
				fb_pixel: null,
				ga: null,
				gtag: null,
				meta_pairs: [],
				thumbnail: null,
				is_published: null,
			},
			courseModel:
			{
				slug: null,
				title: null,
				identifier: null,
				subtitle: null,
				seats_remaining: null,
				img: null,
				thumbnail: null,
			},
			instructorModel:
			{
				name: null,
				job_title: null,
				email: null,
				phone: null,
				img_path: null,
			},
			quoteModel:
			{
				name: null,
				job_title: null,
				text: null,
				rating: null,
				img_path: null,
				date: null,
			},
			localeModel:
			{
				name: null,
				lang_code: null,
				country_code: null,
				img_path: null,
			},
			keyBenefitModel:
			{
				title: null,
				text: null,
			},
			moduleModel:
			{
				title: null,
				text: null,
			},
			promise: null,
			// showPageList: false,



			pageList: [],
			isProcessing: false,
		}
	},

	computed:
	{
		...mapGetters(['isEditingPage', 'getString', 'lang', 'langShort', 'allLanguages']),

		isPanelOpen()
		{
			return (this.panelButton !== null) && (this.panelButton !== 'logout');
		},

		isSiteSelected()
		{
			return (this.panelButton == 0);
		},

		isPagesSelected()
		{
			return (this.panelButton == 1);
		},

		isCollectionsSelected()
		{
			return (this.panelButton == 2);
		},

		isListSelected()
		{
			return (this.panelButton == 3);
		},

		selectedCollectionItem()
		{
			// if (this.selectedCollectionLocaleItems.length == 0)
			// 	return null;

			if (this.selectedCollection &&
				(this.selectedCollection.url=="locale" ||
				this.selectedCollection.url=="redirect")
				)
			{
				return this.selectedCollectionLocaleItems[0];
			}

			if (this.selectedCollection && this.selectedCollectionLocaleItems
				&& this.selectedCollectionLocaleItems.length > 0)
			{
				let locale_id = this.allLanguages[this.selectedCollection.selectedLocale].id;
				for (let item of this.selectedCollectionLocaleItems)
				{
					if (!item)	continue;
					if ( Array.isArray(item) )
					{
						if (item[0].locale_id == locale_id)
							return item;
					}
					else
					if (item.locale_id == locale_id)
						return item;
				}
			}

			// debugger;
			return null;
		},

		pageTypes()
		{
			return this.pageList
					.map(x => x.page_type)
					// filter only unique elements
					.filter( (value, index, self) => self.indexOf(value) === index)
		},

		selectedPage()
		{
			if (!this.pagesPanel.selectedPageLocales || this.pagesPanel.selectedPageLocales.length==0)
				return null;

			return this.pagesPanel.selectedPageLocales[this.pagesPanel.selectedLocale];
		},

		modelSelectorCollection()
		{
			let collection = null;
			// debugger;
			if (this.modelSelectorLabel)
				collection = this.collections
					.find(x => x.url == this.modelSelectorUrl && x.label==this.modelSelectorLabel);
			else if (this.modelSelectorBlockType)
				collection = this.collections
					.find(x => x.url == this.modelSelectorUrl && x.block_type==this.modelSelectorBlockType);
			else
				collection = this.collections
						.find(x => x.url == this.modelSelectorUrl );

			// if (collection && (!collection.data || collection.data.length==0) )
			// {
			// 	debugger;
			// 	this.fetchCollection(collection);
			// }

			return collection;
		},

		livePageUrl()
		{
			if (typeof window=='undefined')
				return this.$route.path.replace('/edit','')
			// debugger;
			// event.preventDefault();
			// event.stopPropagation();
			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			let path = window.location.pathname.replace('/edit','');
			if (!isAcademy)
				path = path.slice(3);
			if (path=="")	path = "/";
			// this.$router.push({ path })
			// this.$router.replace({ location: path })
			var randomStr = btoa(Math.random()).substr(10, 15);
			const url = path + '?' + randomStr;

			return url;
		},

	},

	watch:
	{
		'$route': function(newVal, oldVal)
		{
			this.fetchAllLocalePageData();
			this.fillPageModel();
		},

		// collectionAccordions(newVal)
		// {
		// 	debugger;
		// },

		newPageType(newVal)
		{
			consola.debug('newPageType: ', newVal)
		},

		panelButton(newVal)
		{
			consola.debug('panelButton: ', newVal)

			if (newVal === 0)
			{
				this.fetchSiteData();
			}
			if (newVal == 1)
			{
				this.fetchPagesData();
			}
			if (newVal == 2)
			{
				// this.fetchCollectionList();
			}
		},

		selectedPageLang(newVal)
		{
			this.switchPageDataLocale(newVal)
		},

		selectedCollection(newVal)
		{
			consola.debug('selectedCollection: ', newVal)
		},

		"pagesPanel.selected": function(newVal)
		{
			consola.debug('pagesPanel.selected', newVal);
		},

		modelSelectorCollection(newVal)
		{
			if (newVal)
			{
				consola.debug('fetching modelSelectorCollection: ', newVal)
				this.fetchCollection(newVal)
			}
		},
	},

	created()
	{
		if (typeof window === 'undefined') return;

		this.fetchAllLocalePageData();
		this.setAdminPanelRef({data: this})
	},

	// this is *not* called SSR
	// beforeMount()
	// {
	// 	Vue.component('draggable', vuedraggable)
	// },

	mounted()
	{
		consola.debug('admin-panel mounted()')
		this.$root.$on('reveal:collection', (label, match) => this.onRevealCollection(label, match))
		this.$root.$on('open:cms-list-edit', (params) => this.openListEditor(params))
		this.$root.$on('update:cms-list-edit', (params) => this.saveListEditor(params))
		this.$root.$on('open:cms-entry-edit', (params) => this.editCmsEntryItem(params))
		// this.$root.$on('update:cms-entry-edit', (params) => this.saveCmsEntryItem(params))
		this.$root.$on('open:model-select', (url, label, block_type, get_params) =>
		{
			// NOTE: this assumes that the model can be found in the "collections" array

			// debugger;
			this.modelSelectorUrl = url;
			this.modelSelectorLabel = label;
			this.modelSelectorBlockType = block_type;
			this.modelSelectorGetParams = get_params || null;
			// debugger;
			this.showModelSelector = true;
		})


		this.fillPageModel();
		// this.fetchPagesData();


		this.$eventBus.$on('modify:model', (params) => this.onModifyModel(params))


		this.$eventBus.$off('media:open');
		this.$eventBus.$on('media:open', (params) =>
		{
			consola.debug('on media:open...');
			const { url, ...extra } = params;

			this.$refs.mediaSelector.open();

		})

		this.$eventBus.$on('rest:model', (model) =>
		{
			this.modelStore[model.singletonCounter] = model
			// if (this.modelList.indexOf(model) == -1)
			// 	this.modelList.push = model
		})

		this.$eventBus.$on('rest:string', (str) =>
		{
			this.stringStore[str.singletonCounter] = str

			// if (this.stringList.indexOf(str) == -1)
			// 	this.stringList.push = str
		})

		// this.$eventBus.$on('update:image-locales', (object) =>
		// {
		// 	const field = 'block_id';

		// 	for (let locale of this.pageLocaleData)
		// 	{
		// 		for (let block of locale.block_list)
		// 		{
		// 			var found = trekBlockTree(block, field, object[field] );
		// 			if (found)
		// 			{
		// 				found['img'] = object['img'];
		// 				found['mobile_img'] = object['mobile_img'];
		// 				break;
		// 			}
		// 		}
		// 	}
		// });

		consola.debug('pageLocaleData: ', this.pageLocaleData)

		this.disableEditing = this.isOnlyChrome();

	},

	beforeDestroy()
	{
		this.$root.$off('open:cms-edit');
		this.$root.$off('update:cms-list-edit');
		this.$eventBus.$off('modify:model')
		this.setAdminPanelRef({data: null})
	},

	methods:
	{
		...mapMutations(['setEditingPage', 'setAdminPanelRef', 'setStringTable', 'setString']),

		isOnlyChrome()
		{
			// debugger;
			if (process.browser)
			{
				consola.debug(`browser is ChromeIOS[${isChromeIOS()}] Trident[${isTrident()}] Edge[${isEdge()}] Safari[${isSafari()}] Firefox[${isFirefox()}] Yandex[${isYandex()}] Chrome[${isChrome()}]`);
				var isNotSupported = isChromeIOS() || isTrident() || isEdge() || isSafari() || isFirefox() || isYandex() || !isChrome();
				consola.debug("browser is not supported: ", isNotSupported)
				return isNotSupported;
			}
			else
			{
				return false;
			}
		},

		getDomainName(item)
		{
			if (!item)	return '';
			switch (item.site_id)
			{
				case 1:	return 'corporate';
				case 2:	return 'academy';
				case 0:	return '*';
				default:	return '';
			}
		},

		gotoLivePage(event)
		{
			// debugger;
			// event.preventDefault();
			// event.stopPropagation();
			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			let path = window.location.pathname.replace('/edit','');
			if (!isAcademy)
				path = path.slice(3);
			if (path=="")	path = "/";
			// this.$router.push({ path })
			// this.$router.replace({ location: path })
			var randomStr = btoa(Math.random()).substr(10, 15);
			window.location = path + '?' + randomStr;
			// window.open(window.location.pathname.replace('/edit','').slice(3));
		},

		getUrlOfPageItem(item)
		{
			if (!item)	return '';

			// debugger;

			let host = location.host;
			const isAcademy = host.indexOf('academy') > -1;
			const corpBase = `${location.protocol}//${host.replace('academy.', '')}`;
			const academyBase = `${location.protocol}//${isAcademy ? host : 'academy.'+host}`;

			switch(item.page_type)
			{
				case 'academy':
				case 'course':
				case 'course-roll':
				case 'blended-learning':
				case 'resources':
					return `${academyBase}${item.slug}`;
					break;

				default:
					return `${corpBase}/${this.lang}${item.slug}`;
					break;
			}
		},

		getPageTypeColor(item)
		{
			switch(item.page_type)
			{
				case 'corporate': return 'cyan';
				case 'training': return 'purple';
				case 'course': return 'orange';
				case 'post': return 'lime';
				default:
				case 'generic': return 'grey';
			}
		},

		getDomainColor(item)
		{
			switch (item.site_id)
			{
				case 1:	return 'cyan';//'corporate';
				case 2:	return 'purple';//'academy';
				case 0:	return 'grey';//'*';
				default:	return '';
			}
		},


		isPageUrlEditable(item)
		{
			return ['course', 'post', 'generic', 'expertise-child', 'service-child'].indexOf(item.page_type) > -1;
		},


		identifyModelType(obj)
		{
			if (!obj)	return null;

			if (obj.hasOwnProperty('json') && obj.hasOwnProperty('is_default'))	// is announcement
			{
				return 'json'
			}
			else if (obj.hasOwnProperty('match_list'))	// is announcement
			{
				return 'announcement'
			}
			else if (obj.hasOwnProperty('block_id')) // is block
			{
				return 'block'
			}
			else if (obj.hasOwnProperty('string_id') ||  obj._type=='string') // is string
			{
				return 'string'
			}
			else if (obj.hasOwnProperty('quote_id')) // is quote
			{
				return 'quote'
			}
			else if (obj.hasOwnProperty('page_id') && obj.hasOwnProperty('meta_pairs')) // is page
			{
				return 'page'
			}
			else if (obj.hasOwnProperty('instructor_id') && obj.hasOwnProperty('job_title')) // is instructor
			{
				return 'instructor'
			}
			else if (obj.hasOwnProperty('course_id') && obj.hasOwnProperty('date')) // is date
			{
				return 'date'
			}
			else if (obj.hasOwnProperty('course_id') && obj.hasOwnProperty('duration_info')) // is course
			{
				return 'course'
			}

			return obj._type || null;
		},

		onModifyModel({ obj, field })
		{
			if (this.isSwitchingLocales)	return;

			consola.debug('onModifyModel: ', obj)
			// debugger;
			if (!obj)
			{
				return;
			}

			const type = this.identifyModelType(obj)
			if (type)
			{
				if ( !this.modifiedModelLists[type] ) this.modifiedModelLists[type] = {};
				if ( !this.modelDict[type][obj.id] ) this.modelDict[type][obj.id] = obj;
				if (typeof obj[field]!='object' && obj[field] != this.modelDict[type][obj.id][field] )
					this.modelDict[type][obj.id][field] = obj[field];
				if ( type=='string' )
				{
					if (!obj.locale)
						obj.locale = this.lang;
					this.modifiedModelLists[type][obj.id+'_'+obj.locale] = obj;
				}
				else
					this.modifiedModelLists[type][obj.id] = true;

				// TODO: when switching locales you need to update strings based on the "modifiedModelLists"
			}
		},

		onRegisterModel(obj, replace = true)
		{
			if (!obj)	return obj;
			// var list = [];
			// if ( !Array.isArray(obj) )	list = [obj];
			// else						list = obj;
			// for (var i = 0; i < list.length; i++)
			// {
			// 	const item = list[i];
			// }
			const type = this.identifyModelType(obj)
			let ret = obj;
			if (type)
			{

				// consola.debug('onRegisterModel: ', type)
				if ( !this.modelDict[type] )
					this.modelDict[type] = {};

				if ( this.modelDict[type][obj.id] )
				{
					if ( replace )
					{
						this.modelDict[type][obj.id] = obj;
					}
				}
				else
				{
					this.modelDict[type][obj.id] = obj;
				}


				ret = this.modelDict[type][obj.id];
			}

			if (type=='course')
			{
				if (typeof obj.instructor_list == 'string')
				{
					obj.instructor_list = JSON.parse(obj.instructor_list);
				}
				if (!obj.instructor_list)	obj.instructor_list = [];
			}

			if (type=='page')
			{
				if (obj.course)
				{
					obj.course = this.onRegisterModel(obj.course, replace)
					if (ret) ret.course = obj.course;
				}
				if (obj.site)
				{
					obj.site = this.onRegisterModel(obj.site, replace)
					if (ret) ret.site = obj.site;
				}

				if (obj.menu)
				{
					obj.menu = this.onRegisterModel(obj.menu, replace)
					if (ret) ret.menu = obj.menu;
				}

				if (obj.footer)
				{
					obj.footer = this.onRegisterModel(obj.footer, replace)
					if (ret) ret.footer = obj.footer;
				}
			}

			return ret;
		},

		copyModelProps(obj)
		{
			if (!obj)	return;

			const type = this.identifyModelType(obj);
			if (type)
			{
				if ( !this.modelDict[type][obj.id] )
					this.modelDict[type][obj.id] = obj;
				else
				{
					const dest = this.modelDict[type][obj.id];
					for (const [key, value] of Object.entries(obj))
						dest[key] = obj[key];
				}
			}
		},

		registerListRecursively(list, listType = 'block')
		{
			if (!list)	return null;

			if (listType == 'block')
				for (var i = 0; i < list.length; i++)
				{
					list[i] = this.onRegisterModel(list[i]);
					const item = list[i];

					if ( item.extra_data &&
						Array.isArray(item.extra_data) &&
						!Number.isInteger(item.extra_data[0])
						)
					{
						let table = item.extra_data_table;
						if (table)
						{
							table = table.slice(0, -1); // remove the trailing "s"
						}
						else
							table = 'block';

						this.registerListRecursively(item.extra_data, table);

					}
				}

			else if (listType == 'page')
				for (var i = 0; i < list.length; i++)
				{
					list[i] = this.onRegisterModel(list[i]);
					const item = list[i];



					if ( item.block_list &&
						Array.isArray(item.block_list) &&
						!Number.isInteger(item.block_list[0])
						)
					{
						let table = 'block';

						this.registerListRecursively(item.block_list, table);
					}

				}

			else if (listType == 'course')
				for (var i = 0; i < list.length; i++)
				{
					list[i] = this.onRegisterModel(list[i]);
					const item = list[i];

					if ( item.instructor_list &&
						Array.isArray(item.instructor_list) &&
						!Number.isInteger(item.instructor_list[0])
						)
					{
						let table = 'instructor';

						this.registerListRecursively(item.instructor_list, table);
					}
				}

			else
				for (var i = 0; i < list.length; i++)
				{
					list[i] = this.onRegisterModel(list[i]);
					const item = list[i];
				}

		},

		trekListRecursively(list, listType = 'block', callback)
		{
			if (!callback)	return null;
			if (!list)	return null;

			if (listType == 'block')
				for (var i = 0; i < list.length; i++)
				{
					const item = list[i];
					callback(listType, item);

					if ( item.extra_data &&
						Array.isArray(item.extra_data) &&
						!Number.isInteger(item.extra_data[0])
						)
					{
						let table = item.extra_data_table;
						if (table)
							table = table.slice(0, -1); // remove the trailing "s"
						else
							table = 'block';

						this.trekListRecursively(item.extra_data, table, callback);
					}
				}

			else if (listType == 'page')
				for (var i = 0; i < list.length; i++)
				{
					const item = list[i];
					callback(listType, item);

					if ( item.block_list &&
						Array.isArray(item.block_list) &&
						!Number.isInteger(item.block_list[0])
						)
					{
						let table = 'block';

						this.trekListRecursively(item.block_list, table, callback);
					}
				}

			else if (listType == 'course')
				for (var i = 0; i < list.length; i++)
				{
					const item = list[i];
					callback(listType, item);

					if ( item.instructor_list &&
						Array.isArray(item.instructor_list) &&
						!Number.isInteger(item.instructor_list[0])
						)
					{
						let table = 'instructor';

						this.trekListRecursively(item.instructor_list, table, callback);
					}
				}

			else
				for (var i = 0; i < list.length; i++)
				{
					const item = list[i];
					callback(listType, item);
				}
		},

		purgeCloudflareCache()
		{
			return this.$apiGet('cache-purge')
			.then( resp => consola.debug('cache-purge success: ', resp.data))
			.catch(err => consola.debug('cache-purge error: ', err))
		},

		purgeCacheCmd()
		{
			const self = this;
			self.isProcessing = true;
			this.purgeCloudflareCache()
				.then( ()=>
				{
					this.alert.msg = `CloudFlare cache cleared succesfully!`
					this.alert.type = "success";
				} )
				.catch( () =>
				{
					this.alert.msg = `error clearing CloudFlare cache, try manually`
					this.alert.type = "error";
				} )
				.finally( ()=>
				{
					self.isProcessing = false;
				} )
		},

		clearModelDict()
		{
			// each of the following stores key-value references to objects from the "pageLocaleData" tree
			// not: the object "id" is used as key, not the parent id
			this.modelDict.block = {};
			this.modelDict.course = {};
			this.modelDict.date = {};
			this.modelDict.instructor = {};
			this.modelDict.page = {};
			this.modelDict.string = {};
			this.modelDict.taxonomy = {};
			this.modelDict.quote = {};
			this.modelDict.announcement = {};
			this.modelDict.site = {};
			this.modelDict.json = {};
		},

		clearModifiedModelDict()
		{
			this.modifiedModelLists.block = {};
			this.modifiedModelLists.course = {};
			this.modifiedModelLists.date = {};
			this.modifiedModelLists.instructor = {};
			this.modifiedModelLists.page = {};
			this.modifiedModelLists.string = {};
			this.modifiedModelLists.taxonomy = {};
			this.modifiedModelLists.quote = {};
			this.modifiedModelLists.announcement = {};
			this.modifiedModelLists.site = {};
			this.modifiedModelLists.json = {};
		},


		removeEmptyFilter(value, search, item)
		{
			debugger;
			const headers = this.modelSelectorCollection.headers;
			let isNonEmpty = false;
			headers.forEach( header =>
			{
				if (header.type=='string')
					if (!!item[header.value])
						isNonEmpty = true;
			});

			return isNonEmpty;
		},


		getMenu(menu_id, locale_id)
		{
			let menus = Object.values(this.modelDict.json).filter( x =>
			{
				return x.type=='menu' && x.owner_id==menu_id && x.locale_id==locale_id;
			} )
			if (menus.lenth == 0)
				return null;

			return menus[0];
		},

		getFooter(menu_id, locale_id)
		{
			let menus = Object.values(this.modelDict.json).filter( x =>
			{
				return x.type=='footer' && x.owner_id==menu_id && x.locale_id==locale_id;
			} )
			if (menus.lenth == 0)
				return null;

			return menus[0];
		},

		fetchAllLocalePageData()
		{
			consola.debug('fetchAllLocalePageData()')
			if (typeof window === 'undefined')	return;

			this.pageLocaleDataReady = false;

			const isAcademy = window.location.hostname.indexOf('academy') > -1;

			let parts = window.location.pathname.split('/').filter(x => x.length > 0);
			if (!isAcademy) parts.splice(0, 1); // remove the language (1st part)
			if ( parts[parts.length-1] == 'edit' )	parts.pop(); // remove the last element

			// if ( parts.length > 1 && parts[0]=='course' )
			// 	parts.shift() // remove 1st element

			const slug = '/' + parts.join('/');
			const isSharedPage = (slug.indexOf('/page/')==0);

			var promises = [];
			consola.debug('fetchAllLocalePageData for slug: ', slug)
			this.pageLocaleData = new Array(this.allLanguages.length);
			this.stringLocaleData = new Array(this.allLanguages.length);
			for (let i = 0; i < this.allLanguages.length; i++)
			{
				let lang = this.allLanguages[i];

				// TODO: Need to add site_id

				let options =  { slug, locale: lang.lang_code, };
				if (!isSharedPage)
					options.site_id = (isAcademy ? 2 : 1);

				promises.push(
					this.$apiGet('page-slug/', options )
					.then( (result) =>
					{
						// consola.debug('Object.keys(result): ', Object.keys(result) );
						consola.debug(`locale[${i}] status: `, result.status );
						consola.debug(`locale[${i}]  statusText: `, result.statusText );
						// consola.debug('result: ', result);

						// this.setPageData(result.data[0]);

						this.pageLocaleData[i] = result.data[0];
					})
					.catch( (error) =>
					{
						console.error(`fetchAllLocalePageData<${i}>[1160] error: `, error)
					} )
				);
				promises.push(
						this.$apiGet('string', { locale: lang.lang_code } )
						.then( (result) =>
						{
							// consola.debug('Object.keys(result): ', Object.keys(result) );
							consola.debug(`locale[${i}] status: `, result.status );
							consola.debug(`locale[${i}]  statusText: `, result.statusText );
							// consola.debug('result: ', result);

							// this.setPageData(result.data[0]);
							// debugger;
							this.stringLocaleData[i] = result.data;
							this.registerListRecursively( this.stringLocaleData[i] );
						})
						.catch( (error) =>
						{
							console.error(`fetchAllLocalePageData<${i}>[1944] error: `, error)
						} )
					);
			} // for end

			// if (parts[0] == 'course')
			if (parts[0] == 'ekpaideytika-programmata')
			{
				promises.push( this.fetchCollection( this.collections.find(x => x.url=='course') ) );
			}


			return Promise.all(promises)
				.then( () =>
				{
					consola.debug('All locale page data loaded successfully...', this.pageLocaleData)
					// debugger

					this.clearModelDict();
					this.clearModifiedModelDict();

					for (var i = 0; i < this.pageLocaleData.length; i++)
					{
						this.registerListRecursively(this.pageLocaleData[i].block_list);
						this.onRegisterModel(this.pageLocaleData[i])
					}

					// this.switchPageDataLocale(0);
					this.switchPageDataLocale(1);	// default to Greek
					this.pageLocaleDataReady = true;
				} )
				.catch( error =>
				{
					consola.debug('Loading locale page data failed with error: ', error);
					this.pageLocaleDataReady = false;
				})
		},


		// saveStringStore()
		// {
		// 	this.$root.$loading.start()
		// 	consola.debug('saving...')

		// 	let promises = [];


		// 	let keys = Object.keys(this.stringStore);
		// 	let stringList = [];
		// 	debugger
		// 	for (let i = 0; i < keys.length; i++)
		// 	{
		// 		const item = this.stringStore[keys[i]]
		// 		if (!item)	continue

		// 		stringList.push( item )
		// 	}

		// 	if (stringList.length)
		// 	{
		// 		promises.push( stringList[0].asyncSaveAll( stringList ) )
		// 	}

		// 	this.stringStore = {};

		// 	return promises;

		// },


		// saveModelStore()
		// {
		// 	this.$root.$loading.start()
		// 	consola.debug('saving...')
		// 	// save all the data
		// 	this.isSaving = true;

		// 	let promises = [];

		// 	let keys = Object.keys(this.modelStore);

		// 	for (let i = 0; i < keys.length; i++)
		// 	{
		// 		const item = this.modelStore[keys[i]]
		// 		if (!item)	continue

		// 		promises.push( item.asyncCommit() )
		// 	}

		// 	this.modelStore = {};

		// 	return promises;

		// },


		delayedSaveAllLocalePageData()
		{
			const self = this;

			this.isProcessing = true;
			// if (window.__rich_editor && window.__rich_editor.onBlur)
			// {
			// 	// window.__rich_editor.onBlur()

			// }
			// else
			// 	consola.debug('did not find rich_editor')

			setTimeout(() =>
			{
				self.saveAllLocalePageData();
			}, 500)
		},


		saveAllLocalePageData()
		{
			if (typeof window === 'undefined')	return;

			this.isProcessing = true;
			// this.pageLocaleDataReady = false;

			const isAcademy = window.location.hostname.indexOf('academy') > -1;

			let parts = window.location.pathname.split('/').filter(x => x.length > 0);
			if (!isAcademy) parts.splice(0, 1); // remove the language (1st part)
			if ( parts[parts.length-1] == 'edit' )	parts.pop(); // remove the last element

			// if ( parts.length > 1 && parts[0]=='course' )
			// 	parts.shift() // remove 1st element

			const slug = parts.join('/');
			var promises = [];

			// promises = promises.concat( this.saveStringStore() );
			// // promises = promises.concat( this.saveModelStore() );

			// debugger
			consola.debug(`saveAllLocalePageData for [${slug}]: `, this.pageLocaleData)

			// debugger;

			const modelTypes = Object.keys(this.modifiedModelLists);
			for (let type of modelTypes)
			{
				if ( !type )	continue;
				// const url = (type=='page') ? ('page/'+slug) : type;
				const url = type;
				const dict = this.modifiedModelLists[type];
				if ( !dict )	continue;
				const keys = Object.keys( dict );
				if ( !keys || keys.length == 0 )	continue;
				// const list = keys.map( x => this.modelDict[type][x] )
				const list = [];
				keys.forEach( x =>
				{
					// shallow copy
					let  clone = {};
					if ( type=='string' )
						clone = { ...this.modifiedModelLists[type][x] };
					else
						clone = { ...this.modelDict[type][x] };

					let list_field = null;
					let table = '';

					if ( clone.extra_data &&
						Array.isArray(clone.extra_data) &&
						!Number.isInteger(clone.extra_data[0])
						)
					{
						table = clone.extra_data_table;
						if (table)
						{
							table = table.slice(0, -1); // remove the trailing "s"
						}
						else
							table = this.identifyModelType(clone.extra_data[0]) || 'block';

						list_field = 'extra_data';
						let fieldName = table + '_id';
						clone[list_field] = ( clone[list_field].map(y => y[fieldName]) )
					}

					if ( clone.block_list &&
						Array.isArray(clone.block_list) &&
						!Number.isInteger(clone.block_list[0])
						)
					{
						table = 'block';
						list_field = 'block_list';
						let fieldName = table + '_id';
						clone[list_field] = ( clone[list_field].map(y => y[fieldName]) )
					}

					if ( clone.instructor_list &&
						Array.isArray(clone.instructor_list) &&
						!Number.isInteger(clone.instructor_list[0])
						)
					{
						table = 'instructor';
						list_field = 'instructor_list';
						let fieldName = table + '_id';
						clone[list_field] = ( clone[list_field].map(y => y[fieldName]) )
					}

					list.push(clone);

				})

				consola.debug(`saving ${url} `, list)
				promises.push(
					this.$apiPut( url, list )
					.then( (resp) =>
					{
						// consola.debug('Object.keys(result): ', Object.keys(result) );
						consola.debug(`saveAllLocalePageData ${url} response: `, resp);
					})
					.catch( (error) =>
					{
						console.error(`saveAllLocalePageData[1628] ${url} error: `, error)
					} )
				);

			}


			Promise.all(promises)
				.then( () =>
				{
					consola.debug('All locale page data saved successfully...');
					this.purgeCloudflareCache();
				} )
				.catch( error =>
				{
					consola.debug('Saving locale page data failed with error: ', error);
				})
				.finally( () => this.isProcessing = false )
		},

		getPageLocaleData()
		{
			return this.pageLocaleData;
		},

		refreshPageData()
		{
			const lang_idx = this.selectedPageLang;
			const lang_id = this.allLanguages[lang_idx].id;
			const courses = this.collections
							.find(x => x.url=='course').data
							.filter(x => x.locale_id==lang_id);

			this.refreshMenuFooter();

			this.$eventBus.$emit('set-page-data', this.pageLocaleData[lang_idx], courses);
		},

		refreshMenuFooter()
		{
			const lang_idx = this.selectedPageLang;
			const lang_id = this.allLanguages[lang_idx].id;
			var tempObj = {};

			tempObj = this.getMenu(this.pageLocaleData[lang_idx].menu_id, lang_id);
			if (tempObj)	this.pageLocaleData[lang_idx].menu = tempObj;

			tempObj = this.getFooter(this.pageLocaleData[lang_idx].footer_id, lang_id);
			if (tempObj)	this.pageLocaleData[lang_idx].footer = tempObj;

			// if ( !this.pageLocaleData[lang_idx].menu )	{	debugger; 	}
			// if ( !this.pageLocaleData[lang_idx].footer )	{	debugger; 	}
		},

		switchPageDataLocale(lang_idx)
		{
			if (this.isSwitchingLocales)	return;

			consola.debug('switchPageDataLocale: ', lang_idx)
			this.isSwitchingLocales = true;
			this.selectedPageLang = lang_idx;

			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			// this.$router.replace({
			// 	path: `/${this.allLanguages[lang_idx].lang_code}${window.location.pathname.substring(3)}`
			// 	})
			if (!isAcademy)
				history.replaceState(null, null, `/${this.allLanguages[lang_idx].lang_code}${window.location.pathname.substring(3)}`)

			this.setStringTable({ data: this.stringLocaleData[lang_idx] });

			if (this.pageLocaleData[lang_idx].course)
			{
				// make sure it references the one in 'modelDict' otherwise it will NOT get updated
				this.pageLocaleData[lang_idx].course = this.modelDict['course'][this.pageLocaleData[lang_idx].course.id];

				let list = [];
				for ( let localeData of this.pageLocaleData )
				{
					const obj = localeData.course;
					list.push( obj );

					// copy the object properties to the one in the "modelDict"
					this.copyModelProps(obj)
				}
				this.$eventBus.$emit('set-course-locales', list);
			}

			this.refreshPageData();

			// this.$nextTick( () => { this.isSwitchingLocales = false } )
			setTimeout( () => { this.isSwitchingLocales = false }, 0 )
		},

		closeAdminUI()
		{
			this.panelButton = null;
		},

		getMediaUrl(url)
		{
			if (!url)	return null;
			if (url == 'null')	return null;

			const index = url.indexOf('//');
			consola.debug('getMediaUrl: ', url);

			switch (index) {
				case -1:
					return 'https://' + MEDIA_HOST + url;

				case 0:
					return "http:" + url;

				default:
					return url;
			}
		},

		printDate(isoDate)
		{
			return (new Date(isoDate)).toLocaleDateString()
		},

		printMap(header, val)
		{
			return header.map[val] || val;
		},

		addPageFilter(label, field, value, value_label)
		{
			value_label = value_label || value;
			if ( this.pagesPanel.filters.find(x => x.field==field && x.value==value) )
				return;
			this.pagesPanel.filters.push({label, value_label, field, value})
		},

		filterPages(value, search, item)
		{
			var filter_found = false;
			for (const filter of this.pagesPanel.filters)
			{
				if (item[filter.field]==filter.value)
					filter_found = true;
			}
			if (this.pagesPanel.filters.length > 0 && !filter_found)	return false;
			// if (search)
			// {
			// 	search = search.toLocaleLowerCase();
			// 	if (item.title && item.title.toLocaleLowerCase().indexOf(search) >= 0)	return true;
			// 	if (item.slug && item.slug.toLocaleLowerCase().indexOf(search) >= 0)	return true;
			// 	if (item.description && item.description.toLocaleLowerCase().indexOf(search) >= 0)	return true;
			// 	return false;
			// }

			return true;
		},

		openPageEditor(item)
		{
			consola.debug('openPageEditor: ', item);

			this.pagesPanel.selectedPageLocales = new Array(this.allLanguages.length).fill(null).map(x => ({}) );

			let pageLocales = this.pageList.filter(x => x.page_id == item.page_id );
			for (let i = 0; i < this.allLanguages.length; i++)
			{
				const locale = this.allLanguages[i];
				const page = pageLocales.find(x => x.locale_id == locale.id );
				if (page)
					this.pagesPanel.selectedPageLocales[i] = page;
			}

			// this.selectedPage = item;
			this.selectedPageJSONLD = new Array(this.allLanguages.length).fill({}).map(x => ({}) );
			this.selectedPageLang = 0;
			// debugger;

			// DONE: use "@slug:${item.slug}"" with locale: '*'
			this.fetchSeoData({ owner_url: `@slug:${item.slug}` })
				.then( list =>
				{
					// debugger;
					if (list && list.length == this.allLanguages.length)
					{
						this.selectedPageJSONLD = list.map(x => (x || {}));
						consola.debug('page JSONLD ', this.selectedPageJSONLD)
					}
					else
					{
						consola.debug('page JSONLD error:', list)
					}
				} )

			// this.allLanguages.forEach( (x, index) => {
			// 	this.$apiGet('seo', { owner_url: `@slug:${x.lang_code}/${item.slug}` })
			// 	.then( resp =>
			// 	{
			// 		if (resp)
			// 		{
			// 			// consola.debug(resp.data)
			// 			consola.debug(resp.data[0][0])
			// 			const data = JSON.parse( resp.data[0][0].data || '{}' )
			// 			this.selectedPageJSONLD.splice(index, 1, data || {});
			// 			consola.debug('selectedPageJSONLD['+index+'] ', this.selectedPageJSONLD[index])
			// 		}
			// 	})
			// 	.catch( error =>
			// 	{
			// 		consola.debug('jsonld error: ', error);
			// 	})
			// });
		},

		fillPageModel()
		{
			consola.debug('fillPageModel()')
			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			// last item in array
			let slug = window.location.pathname.replace('/edit', '').split('/').filter(x => !!x);
			if (!isAcademy)
				slug.shift(); // remove 1st element
			// debugger;
			slug = '/' + slug.join('/');
			consola.debug('fillPageModel: ', slug)
			if (!slug)
			{
				consola.debug('fillPageModel error: slug is empty')
				return;
			}

			// TODO: Need to add site_id
			this.$apiGet('page-slug/', { slug, locale: this.lang, shallow: true } )
				.then( resp =>
				{
					if (!resp)
					{
						consola.debug('fillPageModel error: no response')
						return;
					}

					let data = resp.data;
					if ( Array.isArray(data) )
						data = data[0];
					if (!data)
					{
						consola.debug('fillPageModel error: no data')
						return;
					}

					this.pageModel.id = data.id;
					this.pageModel.page_id = data.page_id;
					this.pageModel.page_type = data.page_type;
					this.pageModel.title = data.title;
					this.pageModel.description = data.description;
					this.pageModel.meta_pairs = data.meta_pairs || [];
					if (typeof this.pageModel.meta_pairs == 'string')
						this.pageModel.meta_pairs = JSON.parse(this.pageModel.meta_pairs);
					this.pageModel.thumbnail = data.thumbnail;
					this.pageModel.is_published = data.is_published;

					this.pageModel.slug = data.slug;
					consola.debug('fillPageModel metadata successfully retrieved!', data, this.pageModel)
				} )
				.catch( error =>
				{
					consola.debug('fillPageModel error: ', error)
					this.pageModel.slug = null;
				})
		},

		collectionLocales(event, field)
		{
			// consola.debug(`input[${field}]:`, event);
			for (let item of this.selectedCollectionLocaleItems)
			{
				if (!item)	continue;
				if ( Array.isArray(item) )
				{
					item[0][field] = event;
				}
				else
					item[field] = event;
			}
		},

		updatePageLocales(event, field)
		{
			// consola.debug(`input[${field}]:`, event);
			for (let item of this.pagesPanel.selectedPageLocales)
			{
				if (!item)	continue;
				if ( Array.isArray(item) )
				{
					item[0][field] = event;
				}
				else
					item[field] = event;
			}
		},

		clonePageModelsLocales()
		{
			this.pagesPanel.selectedPageLocales.forEach((x, index) =>
			{
				if (x == this.selectedPage)	return;

				x.slug = this.selectedPage.slug;
				x.is_published = this.selectedPage.is_published;
				x.thumbnail = this.selectedPage.thumbnail;
				x.title = this.selectedPage.title;
				x.description = this.selectedPage.description;
				x.menu_id = this.selectedPage.menu_id;
				x.footer_id = this.selectedPage.footer_id;
				x.ga = this.selectedPage.ga;
				x.gtag = this.selectedPage.gtag;
				x.meta_pairs = cloneDeep(this.selectedPage.meta_pairs);
				x.site_id = this.selectedPage.site_id;
				x.taxonomies = cloneDeep( this.selectedPage.taxonomies );
				this.selectedPageJSONLD[index] = this.selectedPageJSONLD[this.pagesPanel.selectedLocale];
				x.updated_at = this.selectedPage.updated_at;
			})
		},

		clonePageModelsLocalesIfEmpty()
		{
			this.pagesPanel.selectedPageLocales.forEach((x, index) =>
			{
				if (x == this.selectedPage)	return;

				if (!x.slug)  x.slug = this.selectedPage.slug;
				if (!x.is_published)  x.is_published = this.selectedPage.is_published;
				if (!x.thumbnail)  x.thumbnail = this.selectedPage.thumbnail;
				if (!x.title || x.title=="null")  x.title = this.selectedPage.title;
				if (!x.description || x.description=="null")  x.description = this.selectedPage.description;
				if (!x.menu_id)  x.menu_id = this.selectedPage.menu_id;
				if (!x.footer_id)  x.footer_id = this.selectedPage.footer_id;
				if (!x.ga)  x.ga = this.selectedPage.ga;
				if (!x.gtag)  x.gtag = this.selectedPage.gtag;
				if (!x.meta_pairs)  x.meta_pairs = cloneDeep(this.selectedPage.meta_pairs);
				if (!x.site_id)  x.site_id = this.selectedPage.site_id;
				if (!x.taxonomies)  x.taxonomies = cloneDeep( this.selectedPage.taxonomies );
				if (!this.selectedPageJSONLD[index])  this.selectedPageJSONLD[index] = this.selectedPageJSONLD[this.pagesPanel.selectedLocale];
				if (!x.updated_at)  x.updated_at = this.selectedPage.updated_at;
			})
		},

		savePageModel()
		{
			this.isProcessing = true;

			var promises = [];

			consola.debug('Saving page: ', this.selectedPage);

			this.$emit('update:isProcessing', true)
			this.isProcessing = true;

			// debugger;
			const is_published = !!this.selectedPage.is_published;
			const slug = this.fixPageSlug(this.selectedPage.slug, this.selectedPage.page_type);

			this.clonePageModelsLocalesIfEmpty();

			this.pagesPanel.selectedPageLocales.forEach((x, index) =>
			{
				x.slug = slug;
				x.is_published = is_published;
				const data = ( this.selectedPageJSONLD[index] || {} );
				promises.push(
					// this.$apiPut('seo', { owner_url: `@slug:${x.lang_code}/${this.pageModel.slug}`, data })
					this.$apiPut('seo', {
								owner_url: `@slug:${slug}`,
								locale_id: x.id, data
							})
					.then( resp =>
					{
						consola.debug('page seo update: ', resp);
					})
					.catch( error =>
					{
						consola.debug('page seo update error: ', error);
					})
				)
			})

			// this.allLanguages.forEach( (x, index) => {
			// 	// const data = JSON.stringify( this.siteJSONLD[index] );
			// 	const data = ( this.selectedPageJSONLD[index] || {} );
			// 	this.pageModel.slug = this.fixPageSlug(this.pageModel.slug, this.pageModel.page_type);
			// 	promises.push(
			// 		// this.$apiPut('seo', { owner_url: `@slug:${x.lang_code}/${this.pageModel.slug}`, data })
			// 		this.$apiPut('seo', {
			// 					owner_url: `@slug:${this.pageModel.slug}`,
			// 					locale_id: x.id, data
			// 				})
			// 		.then( resp =>
			// 		{
			// 			consola.debug('page seo update: ', resp);
			// 		})
			// 		.catch( error =>
			// 		{
			// 			consola.debug('page seo update error: ', error);
			// 		})
			// 	)
			// });

			// let data = cloneDeep( this.selectedPage );
			// data.locale = this.lang;
			let data = cloneDeep( this.pagesPanel.selectedPageLocales );
			promises.push(
				// this.$apiPut('page/'+ this.selectedPage.slug, data )
				// this.$apiPut('page/', data )
				this.$apiPut('page/', data )
					.then( resp =>
					{
						consola.debug(`${this.selectedPage.slug} update: `, resp);
					} )
					.catch( error =>
					{
						consola.debug(`${this.selectedPage.slug} update error: `, error);
					})
			);

			Promise.all(promises)
				.then( () =>
				{
					consola.debug('Page Model saved successfully...');
					// this.selectedPage = null;
					this.pagesPanel.selectedPageLocales = [];
					this.fetchPagesData();
					this.fillPageModel();
				} )
				.catch( error =>
				{
					consola.debug('Saving Page Model failed with error: ', error);
				})
				.finally( () =>
				{
					this.$emit('update:isProcessing', false)
					this.isProcessing = false;
				} )
		},

		deletePagesSelected()
		{
			if (this.pagesPanel.selected.length == 0)	return;
			let ids = this.pagesPanel.selected.map( x => x.page_id ).filter(x => !!x);
			consola.debug('Delete Pages: ', ids)

			this.$emit('update:isProcessing', true)
			this.isProcessing = true;

			let id_str = ids.join(',');

			consola.debug(`deletePagesSelected...  [${id_str}]`);
			this.$apiDelete('page/' + id_str)
					.then( resp =>
					{
						consola.debug(`deletePagesSelected: `, resp);
						this.pagesPanel.selected = [];
						this.pageList = this.pageList.filter(x => ids.indexOf(x.page_id) == -1)
					} )
					.catch( error =>
					{
						consola.debug(`deletePagesSelected error: `, error);
					})
					.finally( () =>
					{
						this.$emit('update:isProcessing', false)
						this.isProcessing = false;
					} )
		},

		month_year(date)
		{
			if (!date) return null;
			var d = new Date(date);
			return (new Intl.DateTimeFormat(this.lang, { month: 'short' }).format( d ) + ' ' + d.getFullYear());
		},

		fetchSeoData(params)
		{
			return this.$apiGet('seo', params)
				.then( resp =>
				{
					if (resp)
					{
						var list = new Array(this.allLanguages.length).fill(null).map(x => null);
						consola.debug(resp.data[0][0])
						resp.data[0].forEach( (val, index ) =>
						{
							const data = JSON.parse( val.data || '{}' );
							if (typeof data.locale_id != 'undefined')
							{
								var foundIdx = this.allLanguages.findIndex(x => x.id == data.locale_id);
								if (foundIdx > -1)
								{
									list[foundIdx] = data;
								}
							}
							else
							{
								list[index] = data;
							}
							// // using splice() triggers the array reactivity
							// this.siteJSONLD.splice(index, 1, data); // replace item at index
							// consola.debug('site JSONLD['+index+'] ', this.siteJSONLD[index])
						});

						return list;
					}

					return null;
				})
				.catch( error =>
				{
					consola.debug('jsonld error: ', error);
					return null;
				})
		},

		fetchSiteData()
		{
			this.siteJSONLD = new Array(this.allLanguages.length).fill([]);
			this.siteLang = 1; // default to Greek
			this.$apiGet('seo', { owner_url: `@seo` })
				.then( resp =>
				{
					if (!resp || !resp.data)	return;

					consola.debug(resp.data[0][0])
					const data = JSON.parse( resp.data[0][0].data || '{}' );
					this.site_seo.fb_pixel = data.fb_pixel || '';
					this.site_seo.ga = data.ga || '';
					this.site_seo.gtag = data.gtag || '';
				})
				.catch( error =>
				{
					consola.debug('seo error: ', error);
				});
			// this.allLanguages.forEach( (x, index) => {
			// 	this.$apiGet('seo', { owner_url: `@site:${x.lang_code}` })
			// 	.then( resp =>
			// 	{
			// 		if (resp)
			// 		{
			// 			consola.debug(resp.data[0][0])
			// 			const data = JSON.parse( resp.data[0][0].data || '{}' )
			// 			// using splice() triggers the array reactivity
			// 			this.siteJSONLD.splice(index, 1, data); // replace item at index
			// 			consola.debug('site JSONLD['+index+'] ', this.siteJSONLD[index])
			// 		}
			// 	})
			// 	.catch( error =>
			// 	{
			// 		consola.debug('jsonld error: ', error);
			// 	})
			// });

			this.$apiGet('json', { type:'credentials', owner_url: `@site`, name: 'gototraining', locale_id: 1 })
				.then( resp =>
				{
					// debugger;
					if (!resp || !resp.data)	return;

					consola.debug(resp.data[0])
					var data = resp.data[0];
					if (!data)	return;
					if (typeof data.json == 'string')
						data.json = JSON.parse(data.json);

					this.gotoTrainingCredentials = {
						// consumerKey: '',
						// consumerSecret: '',
						// responseKey: '',
						...this.gotoTrainingCredentials,
						...data,
					}
				})
				.catch( error =>
				{
					consola.debug('credentials error: ', error);
				});


			this.fetchSeoData({ owner_url: `@site` })
				.then( list =>
				{
					this.siteJSONLD = list;
					consola.debug('site JSONLD ', this.siteJSONLD)
				} )

			// this.$apiGet('seo', { owner_url: `@site` })
			// 	.then( resp =>
			// 	{
			// 		if (resp)
			// 		{
			// 			consola.debug(resp.data[0][0])
			// 			resp.data[0].forEach( (val, index ) =>
			// 			{
			// 				const data = JSON.parse( val.data || '{}' );
			// 				// using splice() triggers the array reactivity
			// 				this.siteJSONLD.splice(index, 1, data); // replace item at index
			// 				consola.debug('site JSONLD['+index+'] ', this.siteJSONLD[index])
			// 			});
			// 		}
			// 	})
			// 	.catch( error =>
			// 	{
			// 		consola.debug('jsonld error: ', error);
			// 	})
		},

		saveSiteData()
		{
			// const data = JSON.stringify( this.site_seo );
			// const data = ( this.site_seo );
			let toSend = [];
			this.allLanguages.forEach( (x, index) => {
				toSend.push({
					owner_url: `@seo`,
					locale_id: x.id,
					data: this.site_seo,
				})
			});
			this.allLanguages.forEach( (x, index) => {
				const data = ( this.siteJSONLD[index] );
				toSend.push('seo',
					{
						// owner_url: `@site:${x.lang_code}`,
						owner_url: `@site`,
						type: data["@type"],
						locale_id: x.id,
						data
					})
			});

			// debugger;
			return Promise.all(
				[ this.$apiPut('json', this.gotoTrainingCredentials)
						.then( resp =>
						{
							consola.debug('credentials update: ', resp);
						})
						.catch( error =>
						{
							consola.debug('credentials error: ', error);
						})
					,

					// this.$apiPut('seo', { owner_url: `@seo`, data })
					this.$apiPut('seo', toSend)
						.then( resp =>
						{
							consola.debug('seo analytics update: ', resp);
						})
						.catch( error =>
						{
							consola.debug('seo analytics update error: ', error);
					})
				])
				.then(() =>
				{
					this.panelButton = null;
				})
				.catch( error =>
				{
					alert('error: ', error);
				})

			// this.allLanguages.forEach( (x, index) => {
			// 	// const data = JSON.stringify( this.siteJSONLD[index] );
			// 	const data = ( this.siteJSONLD[index] );
			// 	this.$apiPut('seo',
			// 		{
			// 			// owner_url: `@site:${x.lang_code}`,
			// 			owner_url: `@site`,
			// 			locale_id: x.id,
			// 			data
			// 		})
			// 	.then( resp =>
			// 	{
			// 		consola.debug('seo update: ', resp);
			// 	})
			// 	.catch( error =>
			// 	{
			// 		consola.debug('seo update error: ', error);
			// 	})
			// });
		},

		cloneSiteData()
		{
			this.allLanguages.forEach( (x, index) => {
				if (index==this.siteLang)	return;

				this.siteJSONLD.splice(index, 1, cloneDeep(this.siteJSONLD[this.siteLang]) );
			});
		},

		fetchPagesData()
		{
			consola.debug('fetchPagesData()')
			this.pageList = [];
			return this.$apiGet('page', { locale: '*' })
				.then( (resp) =>
				{
					consola.debug('result for pages: ', resp);
					this.pageList = resp.data || [];
				})
				.catch( (error) =>
				{
					consola.debug('error getting result for pages: ', error)
					this.alert.msg = `error getting data for pages`
					this.alert.type = "error";
				} )
		},

		updateCategoryTags(collection)
		{
			consola.debug('updateCategoryTags');
			collection.category_tags = collection.category_tags || [];
			return this.$apiGet('taxonomy', { locale: this.lang, parent_key: 'CLASS_CATEGORIES' } )
				.then( (result) =>
				{
					// consola.debug('Object.keys(result): ', Object.keys(result) );
					// consola.debug('result.status: ', result.status );
					consola.debug('taxonomy[CLASS_CATEGORIES] result: ', result);

					if (result.data)
					{
						collection.category_tags = result.data;
					}
					else
					{
						consola.debug('warning "category_tags" returned empty...')
						collection.category_tags = []
					}

				})
				.catch( (error) =>
				{
					console.error(`fetchCollection[2979] error: `, error)
					this.alert.msg = `error getting taxonomy[CLASS_CATEGORIES]'`;
					this.alert.type = 'error'
				} )
		},

		updateLanguageTags(collection)
		{
			consola.debug('updateLanguageTags');
			collection.language_tags = collection.language_tags || [];
			return this.$apiGet('taxonomy', { locale: this.lang, parent_key: 'LANGUAGES' } )
				.then( (result) =>
				{
					// consola.debug('Object.keys(result): ', Object.keys(result) );
					// consola.debug('result.status: ', result.status );
					consola.debug('taxonomy[LANGUAGES] result: ', result);

					if (result.data)
					{
						collection.language_tags = result.data;
					}
					else
					{
						consola.debug('warning "language_tags" returned empty...')
						collection.language_tags = []
					}

				})
				.catch( (error) =>
				{
					console.error(`fetchCollection[1743] error: `, error)
					this.alert.msg = `error getting taxonomy[LANGUAGES]'`;
					this.alert.type = 'error'
				} )
		},

		updateClassFormatTags(collection)
		{
			consola.debug('updateClassFormatTags');
			collection.format_tags = collection.format_tags || [];
			return this.$apiGet('taxonomy', { locale: this.lang, parent_key: 'CLASS_FORMAT' } )
				.then( (result) =>
				{
					// consola.debug('Object.keys(result): ', Object.keys(result) );
					// consola.debug('result.status: ', result.status );
					consola.debug('taxonomy[CLASS_FORMAT] result: ', result);

					if (result.data)
					{
						collection.format_tags = result.data;
					}
					else
					{
						consola.debug('warning "format_tags" returned empty...')
						collection.format_tags = []
					}

				})
				.catch( (error) =>
				{
					console.error(`fetchCollection[1771] error: `, error)
					this.alert.msg = `error getting taxonomy[CLASS_FORMAT]'`;
					this.alert.type = 'error'
				} )
		},

		async fetchCollection(collection)
		{
			if (!collection)	return Promise.resolve();

			this.$emit('update:isProcessing', true)
			this.isProcessing = true;

			if (collection.url == 'course')
			{
				await Promise.all([
									this.updateCategoryTags(collection),
									this.updateLanguageTags(collection),
									this.updateClassFormatTags(collection) ]);
			}

			if (collection.url == 'string')
			{
				collection.data = this.stringLocaleData;
				for (let locale of collection.data )
					for (let item of locale )
					{
						item._key = `${item.locale_id || ''}_${item.id}`
					}
				// collection.data = [];
				// this.stringLocaleData.forEach(item => {
				// 	collection.data = collection.data.concat(item);
				// });

				this.$emit('update:isProcessing', false)
				this.isProcessing = false;
				return Promise.resolve();
			}

			let url = collection.url;

			// debugger;
			if (collection.isCmdEntry && collection.data && collection.data.length > 0)
			{
				let data = collection.data.flat()
				let ids = data.map( x => x[collection.itemIdProp] );
				// remove duplicates from the array
				ids = ids.filter( (x, idx) => ids.indexOf(x)==idx )

				url += '/' + ids.join(',');
			}

			let params = { locale: '*' };
			if ( collection.apiGetParams )
				params = { ...params, ...collection.apiGetParams };

			return this.$apiGet(url, params)
				.then( (resp) =>
				{
					consola.debug(`result for '${collection.url}': `, resp);
					collection.data = resp.data || [];

					if (collection.url == 'string')
						for (let locale of collection.data )
							for (let item of locale )
							{
								item._key = `${item.locale_id || ''}_${item.id}`
							}
					else
						for (let item of collection.data )
						{
							item._key = `${item.locale_id || ''}_${item.id}`
						}

					if (collection.url == 'course')
					{
						for (let item of collection.data )
							for (let json of item.tuition_json)
								json.content = json.content || '';
					}
				})
				.catch( (error) =>
				{
					consola.debug(`error getting result for '${collection.url}': `, error)
					this.alert.msg = `error getting result for '${collection.url}'`;
					this.alert.type = 'error'
				} )
				.finally( ()=>
				{
					this.$emit('update:isProcessing', false)
					this.isProcessing = false;
				})
		},

		fetchCollectionList()
		{
			for (var i = 0; i < this.collections.length; i++)
			{
				const item = this.collections[i];
				this.fetchCollection(item)
			}
		},

		onToggleOpenCollection(collection, index, event, afterFetchCallback)
		{
			consola.debug(`onToggleOpenCollection(${index})`)

			if (collection.url=='media')
			{
				event && event.preventDefault();
				event && event.stopPropagation();
				this.$refs.mediaSelector.open(false);
				setTimeout( ()=>
				{
					if (Array.isArray( this.collectionAccordions ))
						this.collectionAccordions = this.collectionAccordions.filter(x => x!=index )
					else
						this.collectionAccordions = null;
				})

				return;
			}

			if (collection.url=='string')
			{
				event && event.preventDefault();
				event && event.stopPropagation();
				this.fetchCollection(collection)
					.then( () =>
					{
						this.selectedCollection = collection;
						this.selectedCollectionLocaleItems = collection.data;
						// this.selectedCollectionItem = { ...item }; // clone object
						// this.panelButton = collection.label_singular;
						this.panelButton = collection.url;

						if (afterFetchCallback)
								afterFetchCallback.call(this, collection, index, event)
					} )

				setTimeout( ()=>
				{
					if (Array.isArray( this.collectionAccordions ))
						this.collectionAccordions = this.collectionAccordions.filter(x => x!=index )
					else
						this.collectionAccordions = null;
				})

				return;
			}

			setTimeout( ()=>
			{
				let is_selected = false;
				if (Array.isArray( this.collectionAccordions ))
					is_selected = (this.collectionAccordions.indexOf(index) > -1);
				else
					is_selected = (this.collectionAccordions == index);

				if (is_selected)
				{
					consola.debug('')
					collection.isCmdEntry = false;
					// debugger;
					this.fetchCollection(collection)
						.then( ()=>
						{
							if (afterFetchCallback)
								afterFetchCallback.call(this, collection, index, event)
						} )
					// this.$apiGet(collection.url)
					// 	.then( (resp) =>
					// 	{
					// 		consola.debug(`result for '${collection.url}': `, resp);
					// 		collection.data = resp.data || [];
					// 	})
					// 	.catch( (error) =>
					// 	{
					// 		consola.debug(`error getting result for '${collection.url}': `, error)
					// 	} )
				}
			},
			0 )
		},

		onRevealCollection(label, match)
		{
			var collection = this.collections.filter(x => x.label == label);
			// for (var i = 0; i < this.collections.length; i++)
			// 	if (this.collections[i].label == label)
			// 	{
			// 		collection = this.collections[i];
			// 		colIdx = i;
			// 		break;
			// 	}
			if (!collection || collection.length==0)
			{
				console.error(`error: no collection [${label}] found`)
				return;
			}
			collection = collection[0];
			var colIdx = this.collections.indexOf(collection);

			// this.isPagesSelected = false;
			// this.isSiteSelected = false;
			// this.isCollectionsSelected = true;
			// isCollectionsSelected = true
			this.panelButton = 2;
			this.collectionAccordions = [colIdx];
			// this.isPanelOpen = true;

			const afterFetch = ()=>
			{
				// debugger;
				if (!match)	return;
				const self = this;

				var list = collection.data;
				if (collection.headers[0].filter)
					list = list.filter(x => collection.headers[0].filter(null, null, x))
				for (var i = 0; i < list.length; i++)
				{
					const item = list[i];
					let found = true;
					Object.keys(match).forEach( key => { found = found && item[key]==match[key] } )
					if (found)
					{
						setTimeout( () =>
						{
							if (self[collection.onClick])
								self[collection.onClick](collection, item);
						}, 0)

						break;
					}
				}
			};

			collection.isCmdEntry = false;
			// debugger;
			this.fetchCollection(collection)
				.then( afterFetch )


			// this.onToggleOpenCollection(collection, colIdx, null, afterFetch)
		},

		onClickRowModelSelector(event)
		{
			consola.debug('onClickRowModelSelector: ', event)
			const item = event;

			var localeLists = new Array(this.allLanguages.length).fill(null).map(x => []);

			this.allLanguages.forEach( (x, idx) => {
				localeLists[idx] = this.modelSelectorCollection.data.filter(y => y.locale_id == x.id)
			});

			// find the index of the item
			let itemIndex = localeLists[this.modelSelectorCollection.selectedLocale].findIndex( x => x===item );

			if (itemIndex < 0)
			{
				consola.debug('onClickRowModelSelector error: item not found')
				return;
			}

			var list = [];
			this.allLanguages.forEach( (x, idx) => {
				list.push( localeLists[idx][itemIndex] )
			} )

			this.$root.$emit('selected:model-select', list);
			this.showModelSelector = false;
			setTimeout(() => {
				this.modelSelectorUrl = '';
				this.modelSelectorLabel = null;
			}, 100);
		},

		modifyString(item, newVal, collection)
		{
			if (this.selectedPageLang == collection.selectedLocale)
			{
				this.setString({ key: item.key, value: newVal });
			}
			else
			{
				item.value = newVal;
			}
			this.$eventBus.$emit('modify:model', {obj: item});
		},

		modify(item, field, event)
		{
			// debugger;
			if (!item)	return;
			if (field && typeof event!='undefined')
			{
				// debugger
				if (typeof event == 'object' && Array.isArray(event)==false )
					item[field] = event.target.value;
				else
					item[field] = event;
			}
			this.$eventBus.$emit('modify:model', {obj: item});
		},

		closeCollectionItemEdit()
		{
			this.selectedCollection = null;
			this.selectedCollectionLocaleItems = [];
			this.panelButton = 2;
		},

		onAction(actionName, collection, item)
		{
			this[actionName](collection, item)
		},

		// called before Save
		validateCourse()
		{
			this.fillCourseSeo(this.selectedCollectionLocaleItems);
			if (this.courseJSONLD)
			{
				let list = [];
				this.allLanguages.forEach( (x, index) =>
				{

					var item = {
						type: 'Course',
						owner_url: `@course:${this.selectedCollectionLocaleItems[index].course_id}`,
						locale_id: x.id,
						data: cloneDeep( this.courseJSONLD[index] ),
					}
					list.push( item );
				});
				consola.debug('saving :', list)
				this.$apiPut('seo', list)
					.then( resp =>
					{
						consola.debug('courseJSONLD save resp: ', resp)
					})
					.catch( error =>
					{
						consola.debug('courseJSONLD save error: ', error)
					})
			}

			// for (let item of this.selectedCollectionLocaleItems)
			// {
			// 	// consola.debug('language_list: ', item.language_list);
			// 	// consola.debug('format_list: ', item.format_list);
			// 	item.language_list = item.language_list.map(x => typeof x == 'object' ? x.id : x)
			// 	item.format_list = item.format_list.map(x => typeof x == 'object' ? x.id : x)

			// }

			// you need to create
			// this.selectedCollection.registration_dates = this.selectedCollection.registration_dates.map( x => x.split('T')[0] + 'T21:00:00.000Z' )
			this.selectedCollection.registration_dates = this.selectedCollection.registration_dates.map( x => toDateOnly(x) ).filter(x => !!x);
			if (!this.selectedCollectionItem.slug || !this.selectedCollectionItem.slug.trim())
				return false;
			const slug = this.fixPageSlug(this.selectedCollectionItem.slug, 'course');
			for (let item of this.selectedCollectionLocaleItems)
			{
				item.slug = slug
				item.dates = [ ...this.selectedCollection.registration_dates ]
			}

			this.selectedCollection.registration_dates = [];

			return true;
		},

		setActiveColValue(propName, value)
		{
			// debugger;
			consola.debug('setActiveColValue: ', value);
			this.selectedCollectionItem[propName] = value;
		},

		saveCollectionItem()
		{
			this.isProcessing = true;
			const selectedCollection = this.selectedCollection;


			consola.debug('saveCollectionItem: ', this.selectedCollectionItem)

			if (this.selectedCollection.url!="locale" && this.selectedCollection.url!="redirect")
			{
				// clone properties that need to be shared among all locales
				const cloneSrc = this.selectedCollectionLocaleItems[this.selectedCollection.selectedLocale];
				for (let column of this.selectedCollection.headers)
				{
					// debugger;
					for (let item of this.selectedCollectionLocaleItems)
					{
						// debugger;
						if ((!item[column.value] || item[column.value]=="null") &&
							(typeof cloneSrc[column.value] == "string" || typeof cloneSrc[column.value] == "number") )
							item[column.value] = cloneSrc[column.value];
					}

					if (column.clone_to_all)
					{
						for (let item of this.selectedCollectionLocaleItems)
						{
							item[column.value] = cloneSrc[column.value];
						}
					}
				}
				if (this.selectedCollection.props)
					for (let column of this.selectedCollection.props)
					{
						if (column.clone_to_all)
						{
							for (let item of this.selectedCollectionLocaleItems)
							{
								item[column.value] = cloneSrc[column.value];
							}
						}
					}
			}


			if (this.selectedCollection.onSave)
			{
				if (! this[this.selectedCollection.onSave]() )
				{
					this.isProcessing = false;
					return;
				}
			}

			let data = this.selectedCollectionLocaleItems;
			if (selectedCollection.url == 'string')
			{
				const url = 'string';
				const dict = this.modifiedModelLists['string'];
				if ( !dict )
				{
					this.alert.msg = `${this.selectedCollection.label_singular} no changes found`
					this.alert.type = "error";
					this.isProcessing = false;
					return;
				}

				const list = Object.values(dict);

				if ( !list )
				{
					this.alert.msg = `${this.selectedCollection.label_singular} no changes found`
					this.alert.type = "error";
					this.isProcessing = false;
					return;
				}

				const collection = this.selectedCollection;
				consola.debug(`saving ${url} `, list)
				return this.$apiPut( url, list )
					.then( (resp) =>
					{
						// consola.debug('Object.keys(result): ', Object.keys(result) );
						consola.debug(`saveCollectionItem ${url} response: `, resp);
						// clear the 'string' modified list
						this.modifiedModelLists['string'] = {};
					})
					.catch( (error) =>
					{
						console.error(`saveCollectionItem[3139] ${url} error: `, error)
					} )
					.finally( ()=>
					{
						this.isProcessing = false;

						this.selectedCollection = null;
						this.selectedCollectionLocaleItems = [];
						this.panelButton = 2;

						if (collection.isCmdEntry)
							this.panelButton = null;
					})

			}

			// debugger;
			// this.$apiPut(this.selectedCollection.url, this.selectedCollectionItem)
			this.$apiPut(selectedCollection.url, data)
			.then( (resp)=>
			{
				consola.debug(`update ${selectedCollection.url} success: `, resp);

				// TODO: need to get the dates structures and put them into
				//		'selectedCollectionLocaleItems' and 'modelDict'

				this.alert.msg = `${selectedCollection.label_singular} updated succesfully!`
				this.alert.type = "success";

				// debugger;

				const collection = selectedCollection
				this.fetchCollection(collection)
					.then( ()=>
					{
						// debugger;
						// for each item copy props to 'modelDict'
						if (collection.url == 'string')
							for (let locale of collection.data )
								for (let item of locale )
									this.copyModelProps(item);
						else
							for (let item of collection.data )
								this.copyModelProps(item);
					} )
					.finally( ()=>
					{
						if (selectedCollection.url == 'json')
						{
							this.refreshPageData();
						}

						this.isProcessing = false;

						this.selectedCollection = null;
						this.selectedCollectionLocaleItems = [];
						this.panelButton = 2;

						if (collection.isCmdEntry)
							this.panelButton = null;
					})

			} )
			.catch( (error)=>
			{
				consola.debug(`update ${selectedCollection.url} error: `, error);
				this.alert.msg = `${selectedCollection.label_singular} update error`
				this.alert.type = "error";
				this.isProcessing = false;
			} )
			// .finally( ()=>
			// {
			// 	this.isProcessing = false;
			// })
		},

		removeRegistrationDate(str)
		{
			var idx = this.selectedCollection.registration_dates.indexOf(str);

			if (idx == -1)	return;

			this.selectedCollection.registration_dates.splice(idx, 1);
		},

		fillCourseSeo(localeItems)
		{
			consola.debug('fillCourseSeo')

			// const langMapping = { "english": "en", "greek": "el", "russian": "ru", "french": "fr", "italian": "it"}

			var list = new Array(this.allLanguages.length).fill(null).map(x => {return {};} )
			this.courseJSONLD = list.map( (x, index) =>
			{
				const pageJSONLD_list = this.pageLocaleData[index];
				var companyName = "en-translations";
				let jsonldData = {};
				if (pageJSONLD_list && pageJSONLD_list.jsonld)
				{
					const item = pageJSONLD_list.jsonld.find(p => p.type=="EducationalOrganization");
					jsonldData = item.data ? item.data.address : jsonldData;
					if (item && item.data && item.data.name)
					{
						companyName = item.data.name;
					}
				}

				const course = localeItems[index];
				var obj = {
					"@type": "Course",
					"courseCode": `${course.identifier}`,
					"name": `${course.title}`,
					"description": `${course.subtitle}`,
					"provider":
					{
						"@type": "EducationalOrganization",
						"name": `${companyName}`,
						"url": {"@id": `${location.origin}`},
						"address":
						{
							"@type": "PostalAddress",
							"addressCountry": `${jsonldData.addressCountry}`,
							"addressLocality": `${jsonldData.addressLocality}`,
							"postalCode": `${jsonldData.postalCode}`,
							"streetAddress": `${jsonldData.streetAddress}`,
						},
					}
				};

				if (course.dates && course.dates.length > 0)
				{
					obj["hasCourseInstance"] = new Array(course.dates.length).fill()
					.map((x, idx) =>
					{
						var ret = {
							"@type": "CourseInstance",
							"courseMode": new Array(course.format_list.length).fill()
										.map( (k, index) => course.format_list[index].value ),
							"availableLanguage": new Array(course.language_list.length).fill()
										.map( (k, index) =>
										{
											return {
												"@type": "Language",
												"name": course.language_list[index].value,
												"alternateName": course.language_list[index].key
											}
										}),
							"startDate": toDateOnly(course.dates[idx].date),
							"duration": "P" +
										(!!parseFloat(course.duration_y) ? (course.duration_y+'Y') : '') +
										(!!parseFloat(course.duration_m) ? (course.duration_m+'M') : '') +
										(!!parseFloat(course.duration_d) ? (course.duration_d+'D') : '')	,
							// "endDate": "",
						};

						return ret;
					});

				}


				return obj;
			});
		},

		getResponseKey()
		{
			if ( !this.gotoTrainingCredentials.json.consumerKey )
			{
				alert('ERROR: no "consumerKey" found in credentials');
				return;
			}

			if ( !this.gotoTrainingCredentials.json.consumerSecret )
			{
				alert('ERROR: no "consumerSecret" found in credentials');
				return;
			}

			const authUrl = `https://api.getgo.com/oauth/v2/authorize?client_id=${this.gotoTrainingCredentials.json.consumerKey}&response_type=code`;

			let childWindow = window.open(authUrl, 'Authorize GoToTraining');

			if (!childWindow)
			{
				alert('ERROR: could not open child window')
				return;
			}

		},

		getAccessToken()
		{
			if ( !this.gotoTrainingCredentials.json.consumerKey )
			{
				alert('ERROR: no "consumerKey" found in credentials');
				return;
			}

			if ( !this.gotoTrainingCredentials.json.consumerSecret )
			{
				alert('ERROR: no "consumerSecret" found in credentials');
				return;
			}

			if ( !this.gotoTrainingCredentials.json.responseKey )
			{
				alert('ERROR: no "responseKey" found in credentials');
				return;
			}

			this.credentialsLoading = true;

			const authUrl = `https://api.getgo.com/oauth/v2/token`;

			return this.$axios.post(authUrl,
								`grant_type=authorization_code&code=`+this.gotoTrainingCredentials.json.responseKey,
								{
									headers:
									{
										'Content-Type': 'application/x-www-form-urlencoded',
										'Authorization': 'Basic '+
											window.btoa(this.gotoTrainingCredentials.json.consumerKey + ':' + this.gotoTrainingCredentials.json.consumerSecret),
										'Accept': 'application/json',
									},
								})
					.then( resp =>
					{
						consola.debug('auth response: ', resp);
						const data = resp.data;
						if (data)
						{
							this.gotoTrainingCredentials.json = {
								...this.gotoTrainingCredentials.json,
								...data
							};
						}
					})
					.catch(error =>
					{
						consola.debug('auth error: ', error);
					})
					.finally( () =>
					{
						this.credentialsLoading = false;
					} )

		},

		validateAccessToken()
		{
			if ( !this.gotoTrainingCredentials.json.consumerKey )
			{
				alert('ERROR: no "consumerKey" found in credentials');
				return;
			}

			if ( !this.gotoTrainingCredentials.json.consumerSecret )
			{
				alert('ERROR: no "consumerSecret" found in credentials');
				return;
			}

			if ( !this.gotoTrainingCredentials.json.access_token )
			{
				alert('ERROR: no "access_token" found in credentials');
				return;
			}

			this.credentialsLoading = true;

			return this.$axios.get("https://api.getgo.com/admin/rest/v1/me",
								{
									headers:
									{
										'Authorization': 'Bearer  '+
											this.gotoTrainingCredentials.json.access_token,
										'Accept': 'application/json',
									},
								})
					.then( resp =>
					{
						const data = resp.data;
						consola.debug('auth validate response: ', data);
						if (resp.status >= 200 && resp.status < 300)
						{
							alert('Validation completed successfully!')
						}
						else
						{
							alert('Validation status: ', resp.status)
						}
					})
					.catch(error =>
					{
						consola.debug('auth error: ', error);
						alert('Validation error: ', error)
					})
					.finally( () =>
					{
						this.credentialsLoading = false;
					} )
		},

		editCollectionItem(collection, item)
		{
			let list = [];
			const idx_prop = collection.itemParentIdProp || collection.headers[0].value;

			collection.isCmdEntry = false;

			// search through all the collection data...
			for (let obj of collection.data)
			{
				// ...to find the clicked entry...
				if ( obj[idx_prop] == item[idx_prop] )
				{
					let listObj = {};
					// ...then do a shallow copy of all its fields
					for (const [key, value] of Object.entries(obj))
					{
						if ( Array.isArray(obj[key]) )
						{
							listObj[key] = [ ...obj[key] ]
						}
						else
						{
							listObj[key] = obj[key]
						}
					}

					// ...push it in the list that holds all its locales
					list.push( listObj ); // clone object before pushing
				}
			}

			if (collection.url=='course')
			{
				this.courseJSONLD = null;

				this.fetchSeoData(`@course:${item.course_id}`)
					.then( list =>
					{
						if (!list)
						{
							this.courseJSONLD = new Array(this.allLanguages.length).fill(null).map(x => [])
						}
						else
						{
							this.courseJSONLD = list;
						}
						consola.debug('site JSONLD ', this.siteJSONLD)
					} )


				if (!item.dates)
					collection.registration_dates = []
				else
					// collection.registration_dates = item.dates.map( x => x.date.split('T')[0] )
					collection.registration_dates = item.dates.map( x => toDateOnly(x) ).filter(x => !!x);

				consola.debug('collection.registration_dates: ', collection.registration_dates)
			}

			consola.debug('editCollectionItem: ', list)

			this.selectedCollection = collection;
			this.selectedCollectionLocaleItems = list;
			// this.selectedCollectionItem = { ...item }; // clone object
			// this.panelButton = collection.label_singular;
			this.panelButton = collection.url;

			consola.debug('editCollectionItem: panelButton ', collection.url)
		},

		async editCmsEntryItem(params)
		{
			// editCmsEntryItem
			consola.debug('editCmsEntryItem: ', params);

			this.selectedCollection = this.collections.find(x => x.url == params.type);
			this.selectedCollectionLocaleItems = cloneDeep( params.localeList );
			// need to fill ".data" otherwise fetchCollection() will get all the items in the DB
			this.selectedCollection.data = this.selectedCollectionLocaleItems.flat();

			this.selectedCollection.isCmdEntry = true;


			if (params.type == 'course')
			{
				// copy dates to the 'registration_dates' of the collection
				if (!params.localeList[0].dates)
					this.selectedCollection.registration_dates = []
				else
					// collection.registration_dates = item.dates.map( x => x.date.split('T')[0] )
					this.selectedCollection.registration_dates = params.localeList[0].dates.map( x => toDateOnly(x) ).filter(x => !!x);

				await Promise.all([
									this.updateCategoryTags(this.selectedCollection),
									this.updateLanguageTags(this.selectedCollection),
									this.updateClassFormatTags(this.selectedCollection) ]);
				// this.updateLanguageTags(this.selectedCollection);
				// this.updateClassFormatTags(this.selectedCollection);
			}

			this.panelButton = params.type;

		},

		cloneStringCollectionToOtherLocales()
		{
			const locale_idx = this.selectedCollection.selectedLocale;
			const selectedLocale = this.selectedCollection.data[locale_idx];
			for (let locale of this.selectedCollection.data )
			{
				if (locale == selectedLocale)
					continue;
				for (let item of locale )
				{
					const src = selectedLocale.find(x => x.string_id==item.string_id)
					if (!src)	continue;
					this.modifyString(item, src.value, this.selectedCollection);
				}
			}
		},

		cloneCollectionItemToOtherLocales()
		{
			let list = [];
			const idx_prop = this.selectedCollection.itemParentIdProp || this.selectedCollection.headers[0].value;

			for (let item of this.selectedCollectionLocaleItems)
			{
				// debugger;
				// let obj = { ...this.selectedCollectionItem };
				let obj = cloneDeep( this.selectedCollectionItem );
				obj.id = item.id;
				obj.locale_id = item.locale_id;
				obj[idx_prop] = item[idx_prop];
				list.push( obj )
			}

			this.selectedCollectionLocaleItems = list
		},

		cloneJsonToOtherLocales()
		{
			let list = [];

			for (let item of this.selectedCollectionLocaleItems)
			{
				// let obj = { ...this.selectedCollectionItem };
				item.json = cloneDeep( this.selectedCollectionItem.json );
				item.name = this.selectedCollectionItem.name;
				item.type = this.selectedCollectionItem.type;
				item.owner_table = this.selectedCollectionItem.owner_table;
				item.owner_id = this.selectedCollectionItem.owner_id;
				item.owner_url = this.selectedCollectionItem.owner_url;
				item.is_default = this.selectedCollectionItem.is_default;
				list.push( item )
			}

			this.selectedCollectionLocaleItems = list
		},

		createCollectionItem(collection, item)
		{
			consola.debug('createCollectionItem ', item)

			if (!collection || !collection.url )	return;

			this.$emit('update:isProcessing', true)
			this.isProcessing = true;
			this.$apiPost(collection.url, collection.apiCreateParams || {})
			.then( resp =>
			{
				consola.debug('createCollectionItem resp: ', resp);
				const createdList = resp.data;
				// debugger;
				let list = new Array(this.allLanguages.length).fill(null);
				// debugger;
				if ( Array.isArray(createdList) )
				{
					for ( let item of createdList )
					{
						if (item.errno)
						{
							console.error(`error creating: `, item);
							this.alert.msg = `error creating`;
							this.alert.type = "error";
							return;
						}
						if (collection.url == 'block')
							item.taxonomies = item.taxonomies || [];

						if (collection.url == 'locale' || collection.url == 'redirect')
						{
							list = createdList;
							break;
						}

						for (let i = 0; i < this.allLanguages.length; i++)
						{
							if (item.locale_id == this.allLanguages[i].id)
							{
								this.$eventBus.$emit('modify:model', {obj: item});

								list[i] = item;
								break; // for languages loop
							}
						}
					}

					// Edit the item
					this.selectedCollection = collection;
					this.selectedCollectionLocaleItems = list;
					// this.selectedCollectionItem = { ...item }; // clone object
					// this.panelButton = collection.label_singular;
					this.panelButton = collection.url;
					// consola.debug('panelButton: ', this.panelButton)

					this.fetchCollection(collection)

				}
			} )
			.catch( error =>
			{
				consola.debug(`ERROR createCollectionItem :`, error)
			} )
			.finally( () =>
			{
				// debugger;
				this.$emit('update:isProcessing', false)
				this.isProcessing = false;
			} )

		},

		warnCourseDelete(collection, item)
		{
			let promise = new Promise( (resolve, reject) =>
			{
				this.courseDeletionWarning.cancelCallback = reject;
				this.courseDeletionWarning.acceptCallback = resolve;
			});


			promise
				.then( () =>
				{
					this[collection.acceptDeleteAction](collection, item);
				} )
				.catch( () =>
				{

				})
				.finally( ()=>
				{
					this.courseDeletionWarning.cancelCallback = null;
					this.courseDeletionWarning.acceptCallback = null;
					this.courseDeletionWarning.show = false;
				} )

			this.courseDeletionWarning.show = true;
		},

		deleteCollectionItems(collection, item)
		{
			consola.debug('deleteCollectionItems ', item)

			// debugger;
			if (!collection || !collection.url || !collection.selected)	return;

			this.$emit('update:isProcessing', true)
			this.isProcessing = true;
			// Make sure to include all the locales
			let parentIds = collection.selected.map( x => x[collection.itemParentIdProp] );
			// remove duplicates
			parentIds = parentIds.filter( (x, idx) => parentIds.indexOf(x)==idx );
			// let ids = collection.selected.map( x => x[collection.itemIdProp] ).join(',')
			let ids = collection.data
						.filter( x => parentIds.indexOf(x[collection.itemParentIdProp]) > -1 )
						.map( x => x[collection.itemIdProp] )
						.join(',')
			// this.$apiDelete(collection.url+'/', collection.selected)
			return this.$apiDelete(collection.url+'/'+ids)
				.then( resp =>
				{
					// debugger;
					collection.selected = [];
					this.fetchCollection(collection)
				})
				.catch( error =>
				{
					consola.debug(`ERROR deleteCollectionItems :`, error)
				} )
				.finally( () =>
				{
					// this.$emit('update:isProcessing', false)
					// this.isProcessing = false;
				} )
		},



		openListEditor(params)
		{
			consola.debug('openListEditor: ', params)
			if (!params)
			{
				consola.debug('openListEditor: null params...');
				return;
			}

			// debugger
			this.cmsListEdit.label = params.label;
			this.cmsListEdit.blockIndex = params.blockIndex;
			this.cmsListEdit.innerBlockIndex = params.innerBlockIndex;
			// this.cmsListEdit.parentBlock = params.parentBlock;
			this.cmsListEdit.insertType = params.insertType;
			this.cmsListEdit.grid = params.grid;
			this.cmsListEdit.gridCell = params.gridCell;
			this.cmsListEdit.localeList = params.localeList;
			this.cmsListEdit.extra_data_table = params.extra_data_table;
			this.cmsListEdit.fields = params.fields;
			this.cmsListEdit.type = params.type;
			this.cmsListEdit.block_type = params.block_type;

			consola.debug('openListEditor: ', this.cmsListEdit);

			this.panelButton = 3;
			this.alert.msg = "";
			this.alert.type = "";
		},

		saveListEditor(params)
		{
			if (!params)	return;

			// debugger
			const cmsList = params.cmsList;
			const data = params.data;
			const deletions = params.delete;
			const create = params.create;

			if (data)
			{
				// debugger;
				for (let i = 0; i < this.allLanguages.length; i++)
				{
					const list = data[i];

					// Also save the list because its items (and order) may have change

					for (let item of list)
					{
						item.block_type = item.block_type || cmsList.block_type;
						// copy the data back to the live object hierarchy
						const type = this.identifyModelType(item);
						let obj = this.modelDict[type][item.id];
						if (obj)
						{
							// copy properties
							for (const [key, value] of Object.entries(obj))
							{
								obj[key] = item[key];
							}
						}
						else
						{
							this.onRegisterModel( item );
						}
						this.onModifyModel({obj: item});
					}

					//================================================

					// debugger;

					// re-create the list with objects from the "modelDict"
					let destList = [];
					for (let item of list)
					{
						const type = this.identifyModelType(item);
						let obj = this.modelDict[type][item.id];
						destList.push( obj );
					}

					if (cmsList.innerBlockIndex > -1)
					{
						let obj = this.pageLocaleData[i].block_list[cmsList.blockIndex].extra_data[cmsList.innerBlockIndex];
						obj.extra_data = destList;
						obj = this.modelDict[this.identifyModelType(obj)][obj.id];
						obj.extra_data = destList;
						this.onModifyModel({ obj });
					}
					else
					{
						let obj = this.pageLocaleData[i].block_list[cmsList.blockIndex];
						obj.extra_data = destList;
						obj = this.modelDict[this.identifyModelType(obj)][obj.id];
						obj.extra_data = destList;
						this.onModifyModel({ obj });
					}


					//================================================

				}

				var tempList = this.pageLocaleData;
				this.pageLocaleData = [];
				this.pageLocaleData = tempList;

				this.cmsListEdit.label = '';
				this.cmsListEdit.blockIndex = -1;
				this.cmsListEdit.innerBlockIndex = -1;
				// this.cmsListEdit.parentBlock = '';
				this.cmsListEdit.insertType = '';
			}

			// TODO: Deletions...

			// TODO: Creations...
		},


		onSelectNewPageCourse()
		{
			consola.debug('onSelectNewPageCourse()');

			this.$root.$off('selected:model-select');
			this.$root.$once('selected:model-select', (itemLocales) =>
			{
				// debugger;
				if (!itemLocales)	return;

				this.newPageCourseLocales = cloneDeep(itemLocales);//[0].course_id;

				consola.debug('selected Course ', this.newPageCourseLocales[0].course_id)
			});

			this.$root.$emit('open:model-select', 'course' )
		},

		onSelectCourseInstructor()
		{
			consola.debug('onSelectNewPageCourse()');

			this.$root.$off('selected:model-select');
			this.$root.$once('selected:model-select', (itemLocales) =>
			{
				// debugger;
				if (!itemLocales)	return;

				for (let i = 0; i < this.selectedCollectionLocaleItems.length; i++)
				{
					const item = this.selectedCollectionLocaleItems[i];

					item.instructor = itemLocales[i];
					item.instructor_id = itemLocales[i].instructor_id;
				}

				// this.selectedCollectionItem.instructor_id = itemLocales[0].instructor_id;//[0].course_id;
				// this.selectedCollectionItem.instructor = itemLocales[0];

				consola.debug('selected Course ', itemLocales[0].instructor_id)
			});

			this.$root.$emit('open:model-select', 'instructor' )
		},

		onSelectPageThumbnail(url)
		{
			this.selectedPage.thumbnail = url;
			this.pagesPanel.selectedPageLocales.forEach((x, index) =>
			{
				if (x == this.selectedPage)	return;

				x.thumbnail = this.selectedPage.thumbnail;
			});
		},

		onSelectPageMenu()
		{
			consola.debug('onSelectPageMenu()');

			this.$root.$off('selected:model-select');
			this.$root.$once('selected:model-select', (itemLocales) =>
			{
				// debugger;
				if (!itemLocales)	return;
				if (!this.selectedPage)	return;

				this.selectedPage.menu_id = itemLocales[0].owner_id;
				this.selectedPage.menu = itemLocales[this.allLanguages.findIndex(x => x.id==this.selectedPage.locale_id)]

				this.pagesPanel.selectedPageLocales.forEach((x, index) =>
				{
					if (x == this.selectedPage)	return;

					x.menu_id = this.selectedPage.menu_id;
					x.menu = this.selectedPage.menu;
				});

				// this.onModifyModel({ obj: this.selectedPage });

				consola.debug('selected Menu ', this.selectedPage.menu.name)
			});

			this.$root.$emit('open:model-select', 'json', "Menus" )
		},

		onSelectPageFooter()
		{
			consola.debug('onSelectPageFooter()');

			this.$root.$off('selected:model-select');
			this.$root.$once('selected:model-select', (itemLocales) =>
			{
				if (!itemLocales)	return;
				if (!this.selectedPage)	return;

				this.selectedPage.footer_id = itemLocales[0].owner_id;
				this.selectedPage.footer = itemLocales[this.allLanguages.findIndex(x => x.id==this.selectedPage.locale_id)]

				this.pagesPanel.selectedPageLocales.forEach((x, index) =>
				{
					if (x == this.selectedPage)	return;

					x.footer_id = this.selectedPage.footer_id;
					x.footer = this.selectedPage.footer;
				});

				// this.onModifyModel({ obj: this.selectedPage });

				consola.debug('selected Footer ', this.selectedPage.footer.name)
			});

			this.$root.$emit('open:model-select', 'json', "Footers" )
		},

		// async createBlocks(params = {})
		// {
		// 	try
		// 	{
		// 		const resp = await this.$apiPost('block', params);
		// 		if (resp && resp.data)
		// 		{
		// 			return resp.data;
		// 		}
		// 	}
		// 	catch (error)
		// 	{
		// 		consola.debug('createBlocks Error: ', error)
		// 	}

		// 	return null;
		// },

		// async createBlockHierarchy(list)
		// {
		// 	let ret = [];
		// 	let toCreate = [];
		// 	let hierarchy = [];

		// 	function parseList(params)
		// 	{
		// 		if (!params)	return;
		// 		if ( !Array.isArray(params) )	params = [params];

		// 		for (let item of params)
		// 		{
		// 			if (typeof item == 'string')
		// 			{
		// 				toCreate.push( { block_type: item } )
		// 			}
		// 			else
		// 			{
		// 				// remove the 'extra_data' but keep the rest
		// 				let { extra_data, ...obj } = item;
		// 				toCreate.push( obj )
		// 				parseList(extra_data)
		// 			}
		// 		}
		// 	}

		// 	let blocks = [];
		// 	function assignBlocks(params)
		// 	{
		// 		let result = [];
		// 		if (!params)	return result;
		// 		if ( !Array.isArray(params) )	params = [params];

		// 		for (let item of params)
		// 		{
		// 			if (typeof item == 'string')
		// 			{
		// 				let block = blocks.shift();
		// 				if (block.block_type != item)
		// 					consola.debug(`assignList error: [${block.block_type}] != [${item}] `)
		// 				result.push( block )
		// 			}
		// 			else
		// 			{
		// 				// remove the 'extra_data' but keep the rest
		// 				let { extra_data, ...obj } = item;

		// 				let block = blocks.shift();
		// 				if (block.block_type != obj.block_type)
		// 					consola.debug(`assignList error: [${block.block_type}] != [${obj.block_type}] `)

		// 				block.extra_data = assignBlocks(extra_data);
		// 				result.push( block )
		// 			}
		// 		}

		// 		return result;
		// 	}


		// 	parseList(list);
		// 	if (toCreate.length > 0)
		// 	{
		// 		const resp = await this.$apiPost('block', toCreate);
		// 		if (resp && resp.data)
		// 		{
		// 			let blockLocales = resp.data;
		// 			for (let i = 0; i < blockLocales.length; i += this.allLanguages.length)
		// 				blocks.push( blockLocales[i] )

		// 			if (block.length != toCreate.length)
		// 			{
		// 				consola.debug(`createBlockHierarchy error: requested[${toCreate.length}] != returned[${block.length}]`)
		// 				return null;
		// 			}

		// 			hierarchy = assignBlocks(list);
		// 			if (hierarchy)
		// 				hierarchy = hierarchy.map(x => x.block_id);
		// 			else
		// 				hierarchy = [];

		// 			const pageResp = this.$apiPost('page', {
		// 				block_list: JSONhierarchy
		// 			})
		// 		}
		// 	}

		// 	for (let item of list)
		// 	{
		// 		if (typeof item == 'string')
		// 		{
		// 			toCreate.push( { block_type: item } )
		// 		}
		// 		else
		// 		{

		// 		}
		// 	}
		// },

		onUpdatePageTitleSlug(event)
		{
			this.selectedPage.title = event;
			if (this.pagesPanel.selectedLocale != 0)
			{
				return;
			}
			if ( !this.selectedPage.title )	return;
			let slug = slugify( this.selectedPage.title.toLocaleLowerCase() );
			slug = this.fixPageSlug(slug, this.selectedPage.page_type);
			this.updatePageLocales(slug, 'slug');
		},

		onUpdateTitleSlug(event)
		{
			this.selectedCollectionItem.title = event;
			if (this.selectedCollection.selectedLocale != 0)
			{
				return;
			}
			if ( !this.selectedCollectionItem.title )	return;
			// let slug = slugify( this.selectedCollectionItem.title.toLocaleLowerCase() );
			// slug = this.fixPageSlug(slug, 'course');
			// this.collectionLocales(slug, 'slug');
		},

		fixPageSlug(pageSlug, pageType)
		{
			if (!pageSlug)
			{
				return '';
			}
			if (!pageType)
			{
				// debugger;
				consola.debug('error: no pageType');
				return pageSlug;
			}
			let slug = pageSlug;
			if (slug.indexOf('/') != 0)		slug = '/'+slug;

			switch (pageType)
			{
				case 'course':
					// if (slug.indexOf('/course/') != 0)
					// 	slug = '/course'+slug;
					if (slug.indexOf('/ekpaideytika-programmata/') != 0)
						slug = '/ekpaideytika-programmata'+slug;
					break;

				case 'generic':
					if (slug.indexOf('/page/') != 0)
						slug = '/page'+slug;
					break;
				case 'post':
					if (slug.indexOf('/post/') != 0)
						slug = '/post'+slug;
					break;
				case 'service-child':
				case 'service-template':
					if (slug.indexOf('/yphresies/') != 0)
						slug = '/yphresies'+slug;
					break;
				case 'expertise-child':
				case 'expertise-template':
					if (slug.indexOf('/ekseidikeysh/') != 0)
						slug = '/ekseidikeysh'+slug;
					break;
			}

			return slug;
		},

		async onCreateNewPage()
		{
			consola.debug('onCreateNewPage()');

			this.newPageLoading = true;

			const slug = this.fixPageSlug( this.newPageSlug, this.newPageType ) ;

			const slugResp = await this.$apiGet('page-validate/', { slug } );
			this.newPageSlugError = true;
			if (slugResp && slugResp.data)
			{
				// debugger;

				if ( slugResp.data.status == 404 )
				{
					this.newPageSlugError = false;
				}
			}

			if (this.newPageSlugError)
			{
				this.newPageLoading = false;
				return;
			}

			try
			{
				let pageParams = {
									page_type: this.newPageType,
									slug,
									// block_list: [],
									course_id: null,
								}
				switch (this.newPageType)
				{
					// case 'generic':
					// case 'post':
					// 	{
					// 		const blockLocales = await this.createBlocks({});
					// 		if (blockLocales)
					// 		{
					// 			consola.debug('created blocks: ', blockLocales)
					// 			pageParams.block_list = [ blockLocales[0].block_id ];
					// 		}
					// 	}
					// 	break;

					case 'generic':
					case 'post':
					case 'corporate':
					case 'service-child':
					case 'expertise-child':
						pageParams.site_id = 1;
						break;

					case 'training':
						pageParams.site_id = 2;
						break;

					case 'course':
						{
							pageParams.site_id = 2;
							if (this.newPageCourseLocales)
							{
								pageParams.course_id = this.newPageCourseLocales[0].course_id;
								pageParams.title = this.newPageCourseLocales[0].title;
								pageParams.description = this.newPageCourseLocales[0].subtitle;
								pageParams.thumbnail = this.newPageCourseLocales[0].thumbnail || this.newPageCourseLocales[0].img;
							}
						}
						break;

					default:
						break;
				}

				const pageResp = await this.$apiPost('page', pageParams);
				this.newPageSlugError = false;
				// this.newPageLoading = false;

				await this.fetchPagesData();

				if ( pageResp && pageResp.data )
				{
					const page = pageResp.data[0];
					if (page.error)
					{
						this.alert.msg = page.error;
						this.alert.type = "error";
						this.newPageLoading = false;
						return;
					}

					this.openPageEditor( pageResp.data[0] )
					this.showNewPageDialog = false;
				}
				else
				{
					this.alert.msg = `error creating page`;
					this.alert.type = "error";
				}
				this.newPageLoading = false;

			}
			catch (error)
			{
				consola.debug('onCreateNewPage Error: ', error)
			}

			this.newPageLoading = false;
		},

		copySafeProps(srcItem, dstItem)
		{
			const keys = Object.keys(srcItem);
			for (let prop of keys)
			{
				if ( prop == 'id' )	continue;
				if ( prop.slice(-3)=='_id' )	continue;
				if ( Array.isArray(srcItem[prop]) )	continue;

				dstItem[prop] = srcItem[prop];
			}
		},

		copyListsRecursively(srcList, dstList)
		{
			if (!srcList || !dstList)	return null;

			if (srcList.length != dstList.length)
			{
				consola.debug(`error: list lengths don't match `, srcList, dstList)
			}

			if (srcList.length == 0)
			{
				return;
			}

			let listType = this.identifyModelType(srcList[0]);

			if (listType == 'block')
			{
				for (var i = 0; i < srcList.length; i++)
				{
					const srcItem = srcList[i];
					const dstItem = dstList[i];
					if (!dstItem)	continue;

					this.onModifyModel({obj: dstItem})

					for (let prop of blockCopyableFields)
						dstItem[prop] = srcItem[prop];

					if ( srcItem.extra_data &&
						Array.isArray(srcItem.extra_data) &&
						!Number.isInteger(srcItem.extra_data[0])
						)
					{
						if (srcItem.extra_data.length == 0)
						{
							dstItem.extra_data = []
						}
						else
							this.copyListsRecursively(srcItem.extra_data, dstItem.extra_data);
					}
				}
			}
			else if (listType == 'page')
			{
				for (var i = 0; i < srcList.length; i++)
				{
					const srcItem = srcList[i];
					const dstItem = dstList[i];
					if (!dstItem)	continue;

					this.onModifyModel({obj: dstItem})

					this.copySafeProps(srcItem, dstItem)

					if (srcItem.site)
					{
						dstItem.site = cloneDeep(srcItem.site);
					}

					if ( srcItem.block_list &&
						Array.isArray(srcItem.block_list) &&
						!Number.isInteger(srcItem.block_list[0])
						)
					{
						this.copyListsRecursively(srcItem.block_list, dstItem.block_list);
					}

					if ( srcItem.announcements &&
						Array.isArray(srcItem.announcements) &&
						!Number.isInteger(srcItem.announcements[0])
						)
					{
						this.copyListsRecursively(srcItem.announcements, dstItem.announcements);
					}

					if ( srcItem.jsonld &&
						Array.isArray(srcItem.jsonld) &&
						!Number.isInteger(srcItem.jsonld[0])
						)
					{
						this.copyListsRecursively(srcItem.jsonld, dstItem.jsonld);
					}

				}
			}
			else if (listType == 'course')
			{
				for (var i = 0; i < srcList.length; i++)
				{
					const srcItem = srcList[i];
					const dstItem = dstList[i];
					if (!dstItem)	continue;

					this.onModifyModel({obj: dstItem})

					for (let prop of courseCopyableFields)
						dstItem[prop] = srcItem[prop];

					if (srcItem.language_list)
					{
						if (Array.isArray(srcItem.language_list))
							dstItem.language_list = [...srcItem.language_list];
						else
							dstItem.language_list = srcItem.language_list;
					}

					if (srcItem.category_list)
					{
						if (Array.isArray(srcItem.category_list))
							dstItem.category_list = [...srcItem.category_list];
						else
							dstItem.category_list = srcItem.category_list;
					}

					if (srcItem.format_list)
					{
						if (Array.isArray(srcItem.format_list))
							dstItem.format_list = [...srcItem.format_list];
						else
							dstItem.format_list = srcItem.format_list;
					}

					if (srcItem.dates)
					{
						if (Array.isArray(srcItem.dates))
							dstItem.dates = [...srcItem.dates];
						else
							dstItem.dates = srcItem.dates;
					}

					if ( srcItem.instructor_list &&
						Array.isArray(srcItem.instructor_list) &&
						!Number.isInteger(srcItem.instructor_list[0])
						)
					{
						// let table = 'instructor';

						this.copyListsRecursively(srcItem.instructor_list, dstItem.instructor_list);
					}
				}
			}
			else
			{
				for (var i = 0; i < srcList.length; i++)
				{
					const srcItem = srcList[i];
					const dstItem = dstList[i];
					if (!dstItem)	continue;

					this.onModifyModel({obj: dstItem})

					this.copySafeProps(srcItem, dstItem)
				}
			}

		},

		clonePageLocale()
		{
			for (let i = 0; i < this.allLanguages.length; i++)
			{
				if (i==this.selectedPageLang)	continue;

				this.copyListsRecursively(
						[ this.pageLocaleData[this.selectedPageLang] ], 	// source
						[ this.pageLocaleData[i] ] )						// destination

			}
		},

		exportJSON()
		{
			// consola.debug('exportJSON()');
			const toDel = ['created_at', 'updated_at', 'has_taxonomy', 'mobile_img', 'img_path', 'img', 'thumbnail', 'list_thumbnail', 'language_list', 'format_list', 'category_list', 'dates', '_key', 'block_type', 'page_type', 'taxonomies', 'pivot', ];

			function cleanJsonTree(obj)
			{
				if (!obj)	return obj;

				if (Array.isArray(obj))
				{
					obj.forEach(x => cleanJsonTree(x))
				}
				else if (typeof obj == 'object')
				{
					const keys = Object.keys(obj);

					keys.forEach(x => {
						if (toDel.includes(x) || obj[x]===null || obj[x]==="" ||
							(Array.isArray(obj[x]) && obj[x].length==0) ||
							(typeof obj[x] == 'object' && Object.keys(obj).length==0))
						{
							delete obj[x];
							return;
						}
						if (Array.isArray(obj[x]) || typeof obj[x] == 'object')
						{
							cleanJsonTree(obj[x])
							return;
						}
					})
				}

				return obj;
			}

			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			let path = location.pathname.split('/').filter(x => !!x);
			if (!isAcademy)
				path = path.slice(1);
			path = path.slice(0, -1).join('_');


			for (var i = 0; i < this.pageLocaleData.length; i++)
			{
				let page = this.pageLocaleData[i];
				let json = JSON.stringify(page);
				page = JSON.parse(json)
				// consola.debug('json page: ', page)

				let lang = this.allLanguages.filter(x => page.locale_id==x.id);
				if (!lang.length)	continue;
				lang = lang[0];
				lang = lang.lang_code;

				delete page.site;
				delete page.menu;
				delete page.footer;
				delete page.jsonld;

				page = cleanJsonTree(page);
				json = JSON.stringify(page);

				const blob = new Blob([json], {type: "application/json;charset=utf-8"});
				saveAs(blob, lang+"_"+path+".json");
			}

		},

		savePageFromJSON(obj)
		{
			if (!obj)	return Promise.resolve(true);

			const { block_list, ...page } = obj;

			// delete page.block_list;

			let blocks = [];
			let instructors = [];
			let courses = [];
			let quotes = [];

			this.trekListRecursively(block_list, 'block', (type, item) =>
			{
				if (type=='block')
				{
					// if (item.hasOwnProperty('taxonomies'))
					// 	delete item.taxonomies;
					item.ignore_taxonomies = true;
					blocks.push( item )
				}
				else if (type=="instructor")
					instructors.push( item )
				else if (type=="course")
					courses.push( item )
				else if (type=="quote")
					quotes.push( item )
				else
					consola.debug(`error: unknown item type[${type}]`)
			})

			function clean(obj)
			{
				for (const prop in obj)
					if (Array.isArray(obj[prop]) || typeof obj[prop] == 'object')
						delete obj[prop]
			}

			blocks.forEach(clean);
			instructors.forEach(clean);
			courses.forEach(clean);

			let promises = [];
			promises.push( this.$apiPut('page', page) );
			if (blocks.length)
				promises.push( this.$apiPut('block', blocks) );

			if (instructors.length)
				promises.push( this.$apiPut('instructor', instructors) );

			if (courses.length)
				promises.push( this.$apiPut('course', courses) );

			if (quotes.length)
				promises.push( this.$apiPut('quote', quotes) );
			// block_list.forEach(x => this.onModifyModel(x) )

			return Promise.all(promises);
		},

		importJSON()
		{
			consola.debug('importJSON()')

			const el = document.getElementById('JSONfileInput');
			const self = this;
			let jsonList = [];
			let length = 0;

			function checkFinished()
			{
				length--;

				if (length==0)
				{
					consola.debug('importing JSON...')
					if (self.$refs.jsonImportBtn)
						self.$refs.jsonImportBtn.loading = true;
					self.isProcessing = true;

					for (let i = 0; i < jsonList.length; i++)
					{
						try
						{
							jsonList[i] = JSON.parse(jsonList[i]);
						}
						catch(error)
						{
							alert('ERROR: json file is malformed: ', error);
							jsonList[i] = null;
						}
					}

					Promise.all( jsonList.map(x => self.savePageFromJSON(x) ) )
						.then( values =>
						{
							self.alert.msg = "JSON imported successfully!";
							self.alert.type = "success";
							// self.fetchAllLocalePageData();
						})
						.catch( error =>
						{
							self.alert.msg = "Error importing JSON";
							self.alert.type = "error";
						} )
						.finally( () =>
						{
							if (self.$refs.jsonImportBtn)
								self.$refs.jsonImportBtn.loading = false;
							self.isProcessing = false;
							self.purgeCloudflareCache();
						} )

				}

				if (length < 0)
				{
					consola.debug('checkFinished Error:', length);
				}
			}


			el.onchange = function(event)
			{
				const fileList = event.target.files;

				length = fileList.length;
				jsonList = new Array(fileList.length);

				for (let i = 0; i < fileList.length; i++)
				{
					const reader = new FileReader();
					const file = fileList[i];
					reader.readAsText(file);
					reader.onload = function()
					{
						jsonList[i] = reader.result;
						checkFinished();
					}
				}
			};

			el.click();
		},




		//===============================================
		//===============================================
		//===============================================







		createCmd(item)
		{
			this.showAdminPanel = 'create';
			this[item.cmd]();
		},

		createGenericPage()
		{
			this.showAdminSubPanel = 'generic-page';
		},

		createCourse()
		{
			this.showAdminSubPanel = 'course';
		},

		createInstructor()
		{
			// this.showAdminSubPanel = 'instructor';
			this.$eventBus.$emit('instructor:open', {url: 'instructor'})
		},

		createQuote()
		{
			this.showAdminSubPanel = 'quote';
		},

		createLocale()
		{
			this.showAdminSubPanel = 'locale';
		},

		createKeyBenefit()
		{
			this.showAdminSubPanel = 'key benefit';
		},

		createModule()
		{
			this.showAdminSubPanel = 'module';
		},

		onEditMetadata()
		{
			this.showAdminPanel = 'create';
			this.showAdminSubPanel = 'metadata';
		},

		// onRemoveMeta(index)
		// {
		// 	// alert('TODO: onRemoveMeta()')
		// 	this.pageModel.meta_pairs.splice(index, 1)
		// },

		// onAddMetaName()
		// {
		// 	// alert('TODO: onAddMetaName()')
		// 	this.pageModel.meta_pairs.push({name: '', content: ''});
		// },

		// onAddMetaProp()
		// {
		// 	// alert('TODO: onAddMetaProp()')
		// 	this.pageModel.meta_pairs.push({property: '', content: ''});
		// },

		onDeletePage()
		{
			this.isProcessing = true;
			// alert('TODO: onDeletePage()')
			consola.debug('deleting page ' + this.pageModel.page_id)
			this.$apiDelete('page/' + this.pageModel.page_id)
				.then( resp =>
				{
					if (!resp.data)
					{
						consola.debug('onDeletePage: no data', resp)
					}
					if (resp.error)
					{
						consola.debug('onDeletePage: error ', resp.error)
					}

					consola.debug(`onDeletePage [${this.pageModel.slug}] success!`);

					this.gotoPage(this.pageList[this.pageList.length-1]);
					this.showAdminPanel = null;
				})
				.catch( error =>
				{
					consola.debug('onDeletePage error: ', error)
				} )
				.finally( ()=>
				{
					this.isProcessing = false;
				})
		},

		onSaveMetadata()
		{
			const isAcademy = window.location.hostname.indexOf('academy') > -1;
			let page_slug = this.$route.path.replace('/edit','');//.slice(3);
			if (!isAcademy)
				page_slug = page_slug.slice(3);

			// const slug = window.location.pathname.split('/').pop()
			const data = {
				id: this.pageModel.id,
				page_id: this.pageModel.page_id,
				slug: this.pageModel.slug,
				title: this.pageModel.title,
				description: this.pageModel.description,
				fb_pixel: this.pageModel.fb_pixel,
				ga: this.pageModel.ga,
				gtag: this.pageModel.gtag,
				is_published: this.pageModel.is_published,
				thumbnail: this.$refs.page_thumbnail.postData.thumbnail,
				meta_pairs: JSON.stringify(this.pageModel.meta_pairs),
			};

			this.isProcessing = true;
			consola.debug('data: ', data)
			// debugger;
			const url = 'page'
			this.$apiPut(url, data)
			.then( (resp)=>
			{
				consola.debug(`update ${url} success: `, resp);
				this.showAdminPanel = null;
				if (data.slug != page_slug)
				{
					switch(this.pageModel.page_type)
					{
						default:
						// case 'corporate':
						// case 'training':
							if (isAcademy)
								this.$router.replace( data.slug )
							else
								this.$router.replace( `/${this.lang}/` + data.slug )
							break;


						// case 'course':
						// 	if (isAcademy)
						// 		this.$router.replace( `/${this.lang}/course/` + data.slug )
						// 	else
						// 		this.$router.replace( `/${this.lang}/course/` + data.slug )
						// 	// debugger
						// 	// this.$refs.gotoPageMenu.visible(true)
						// 	break;
					}
				}
				this.fetchPagesData();
			} )
			.catch( (error)=>
			{
				consola.debug(`update ${url} error: `, error);
			} )
			.finally( ()=>
			{
				this.isProcessing = false;
			})
		},

		gotoPage(item)
		{
			switch(item.page_type)
			{
				default:
				case 'corporate':
					this.$router.push( this.$localiseUrl(item.slug, true) )

				case 'training':
				case 'course':
					this.$router.push( (item.slug) )
					// debugger
					// this.$refs.gotoPageMenu.visible(true)
					break;
			}
		},

		doCreate(url, data, modelName)
		{
			this.isProcessing = true;
			consola.debug('data: ', data)
			// debugger;
			this.$apiPost(url, data)
			.then( (resp)=>
			{
				consola.debug(`create ${url} success: `, resp);
				this.showAdminPanel = null;

				let obj = {}
				for (let prop in data)
				{
					obj[prop] = null;
				}
				this[modelName] = obj;
			} )
			.catch( (error)=>
			{
				consola.debug(`create ${url} error: `, error);
			} )
			.finally( ()=>
			{
				this.isProcessing = false;
			})
		},

		onCreateGenericPage()
		{
			let slug = this.pageModel.slug;
			if (!slug)	return;
			// slug = encodeURIComponent(slug);

			const data = {
				title: this.pageModel.title,
				slug: this.pageModel.slug,
				page_type: 'generic',
				is_published: 1
			};

			this.$apiGet('page-validate/', { slug } )
				.then( (resp)=>
				{
					consola.debug('slug validate resp: ', resp);
					if (resp.data.status==404) // the slug does not exist
					{
						// return data;
						return this.$apiPost('page', data);
					}
					return null;
				} )
				.catch( (error)=>
				{
					consola.debug('slug validate error: ', error)
					return null;
				} )

				//-----------------------------------
				.then( (resp) =>
				{
					consola.debug('create page response: ', resp);
					if (!resp)	return null;

					consola.debug('create page data: ', resp.data);
					if (!resp.data.error)
					{
						this.showAdminPanel = null;
						this.fetchPagesData();
						consola.debug('creat page success! ');
					}
					else
					{
						consola.debug('creat page error: ', resp.data.error);
					}

				} )
				.catch( (error)=>
				{
					consola.debug('create page error: ', error);
					return null;
				} )
				//------------------------------------
				.finally( ()=>
				{
					this.isProcessing = false;
				})

			// this.doCreate('course', data, 'courseModel');
		},

		onCreateCourse()
		{
			const data = {
				title: this.courseModel.title,
				identifier: this.courseModel.identifier,
				subtitle: this.courseModel.subtitle,
				seats_remaining: this.courseModel.seats_remaining,
				img: this.$refs.course_img.postData.img,
				thumbnail: this.$refs.course_thumbnail.postData.thumbnail,
			};

			let slug = this.courseModel.slug;
			if (!slug)	return;
			// slug = encodeURIComponent(slug);
			this.$apiGet('page-validate/', { slug })
				.then( (resp)=>
				{
					consola.debug('slug validate resp: ', resp);
					if (resp.data.status==404) // the slug does not exist
					{
						// return data;
						return this.$apiPost('course', data);
					}
					return null;
				} )
				.catch( (error)=>
				{
					consola.debug('slug validate error: ', error)
					return null;
				} )
				//-----------------------------------
				.then( (resp) =>
				{
					consola.debug('create course response: ', resp);
					if (!resp)	return null;

					consola.debug('create course data: ', resp.data);
					if (resp.status == 200 && resp.data.length > 0)
					{
						const course_id = resp.data[0].course_id;
						const title = resp.data[0].title;
						return this.$apiPost('page',
						{
							slug: slug,
							page_type: 'course',
							course_id: course_id,
							title: title,
						});
					}
				} )
				.catch( (error)=>
				{
					consola.debug('create course error: ', error);
					return null;
				} )
				//-----------------------------------
				.then( (resp) =>
				{
					consola.debug('create page response: ', resp);
					if (!resp)	return null;

					consola.debug('create page data: ', resp.data);
					if (!resp.data.error)
					{
						this.showAdminPanel = null;
						this.fetchPagesData();
						consola.debug('creat page success! ');
					}
					else
					{
						consola.debug('creat page error: ', resp.data.error);
					}

				} )
				.catch( (error)=>
				{
					consola.debug('create page error: ', error);
					return null;
				} )
				//------------------------------------
				.finally( ()=>
				{
					this.isProcessing = false;
				})

			// this.doCreate('course', data, 'courseModel');
		},

		onCreateInstructor()
		{
			const data = {
				name: this.instructorModel.name,
				job_title: this.instructorModel.job_title,
				email: this.instructorModel.email,
				phone: this.instructorModel.phone,
				img_path: this.$refs.instructor_img.postData.img_path,
			};
			this.doCreate('instructor', data, 'instructorModel');
		},

		onCreateQuote()
		{
			const data = {
				name: this.quoteModel.name,
				job_title: this.quoteModel.job_title,
				text: this.quoteModel.text,
				rating: this.quoteModel.rating,
				date: this.quoteModel.date,
				img_path: this.$refs.quote_img.postData.img_path,
			};
			this.doCreate('quote', data, 'quoteModel');
		},

		onCreateLocale()
		{
			const data = {
				name: this.localeModel.name,
				lang_code: this.localeModel.lang_code,
				country_code: this.localeModel.country_code,
				img_path: this.$refs.locale_img.postData.img_path,
			};
			this.doCreate('locale', data, 'localeModel');
		},

		onCreateKeyBenefit()
		{
			const data = {
				short_title: this.keyBenefitModel.title,
				content: this.keyBenefitModel.text,
				block_type: 'key benefit',
			};
			this.doCreate('block', data, 'keyBenefitModel');
		},

		onCreateModule()
		{
			const data = {
				title: this.moduleModel.title,
				content: this.moduleModel.text,
				block_type: 'course outline module',
			};
			this.doCreate('block', data, 'moduleModel');
		},

		onLogout()
		{
			this.panelButton = 'logout';

			this.$auth.logout()
				.then( resp =>
				{
					location.href = this.$route.path.replace('/edit', '')
					// this.$router.push( this.$route.path.replace('/edit', '') )
					// // location.reload();
				})
				.catch( error =>
				{
					consola.debug('onLogout error: ', error)
				})
		}
	}
}
